import * as React from "react";
import styled from "styled-components";
import appImg from "../../assets/png/appAuthImg.png";

const StyledContainer = styled.div`
  color: #b2b2b2;
  font-weight: 800;
  padding-left: 50px;
  margin-left: 5px;
  font-size: 18px;
  position: absolute;
  top: 13%;
  padding: 10px;
  border: 1px solid #c8c8c8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow-y: auto;
  max-height: 75%;
  width: 98%;
`;

const AuthLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <div style={{ height: "100vh" }}>
      <img src={appImg} width={"100%"} height={"100%"} alt={"background"} />
      <StyledContainer>{props.children}</StyledContainer>
    </div>
  );
};

export default AuthLayout;
