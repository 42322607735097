import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import axios from "axios";
import * as React from "react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import CurrentUserContext from "../../CurrentUserContext";
import "./AdminAuth.css";

const AdminAuthNav = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const selectedRoute = params.pathname.substring(1);
  const [route, setRoute] = React.useState("profile");
  const onLinkClick = (item?: PivotItem) => {
    if (item?.props.itemKey) {
      navigate(`/${item.props.itemKey}`);
    }
  };

  React.useEffect(() => {
    setRoute(selectedRoute);
  }, [selectedRoute]);
  const context = useContext(CurrentUserContext);
  const email = context?.user?.email;
  const { data, isLoading } = useQuery("getSMEProfileImage", () => {
    return axios.get(
      `https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/${email}`
    );
  });
  const profilePath = data?.data?.profilePictureLocation || "";
  const navbarStyles: Partial<IPivotStyles> = {
    root: { backgroundColor: "black", textAlign: "right", height: "70px" },
    link: {
      backgroundColor: "black",
      color: "white",
      height: "70px",
      "&:hover": {
        backgroundColor: "grey",
        height: "70px",
      },
    },
    linkIsSelected: selectedRoute.includes("forget-password")
      ? {
          backgroundColor: "black !important",
          color: "white !important",
          height: "70px",
          "&:hover": {
            backgroundColor: "grey",
            height: "70px",
          },
        }
      : {
          backgroundColor: "white !important",
          color: "black !important",
          height: "70px",
        },
  };

  return (
    <>
      <Pivot
        aria-label="Admin Navbar"
        linkSize="large"
        linkFormat="tabs"
        onLinkClick={onLinkClick}
        selectedKey={route}
        styles={navbarStyles}
      >
        <PivotItem headerText="Reports" itemKey="reports" />
        <PivotItem headerText="Expense Reports" itemKey="expense-reports" />
        <PivotItem headerText="Sign Out" itemKey="sign-out" />
      </Pivot>
      {isLoading
        ? "Loading image..."
        : profilePath && (
            <img src={profilePath} className="profile_img" alt="Profile" />
          )}
    </>
  );
};

export default AdminAuthNav;
