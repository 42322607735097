import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import {
  PrimaryButton,
  Label,
  Dropdown,
  IDropdownOption,
  Toggle,
  mergeStyles,
  Checkbox,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
} from "./SmeAppointmentForm.styles";
import {
  SmeAppointmentType,
  getInitialData,
  getTimeInterval,
  schema,
  getUnAvailableData,
  getExistingForecast,
} from "./SmeAppointmentForm.utils";
import { getServerError } from "../../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import format from "date-fns/format";
import MyAppointments from "../../CustomerAppointment/MyAppointments/MyAppointments";
import usePaymentMethodValidation from "../../../utils/usePaymentMethodValidation";
import { useNavigate } from "react-router-dom";

interface SmeRegistrationFormProps {
  userData: {
    lastName: string;
    email: string;
    firstName: string;
  };
}

const endpoint = "sme/appointments";

const SmeRegistrationForm = ({ userData }: SmeRegistrationFormProps) => {
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };
  const [showFreeTime, setShowFreeTime] = React.useState<boolean>(true);
  const [hideConflicts, setHideConflicts] = React.useState<boolean>(true);
  const [existingSchedule, setExistingSchedule] = React.useState<
    SmeAppointmentType | undefined
  >(undefined);

  const { data, isLoading } = useQuery("getForeCastData", () => {
    return axios.get(
      `https://1b2jwnwxo5.execute-api.us-east-2.amazonaws.com/Prod/by/${userData.email}`
    );
  });

  const handleSuccess = () => {
    alert("Appointment Schedule is successfully updated");
  };

  const mutation = useMutation(
    (data: SmeAppointmentType) => {
      return axios.post(
        "https://1b2jwnwxo5.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const { setFieldValue, values, handleSubmit, errors, touched } = useFormik({
    initialValues: getInitialData(
      userData.email,
      userData.firstName,
      userData.lastName,
      existingSchedule
    ),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.forecastId = values.forecastId ? values.forecastId : Date.now();
      mutation.mutate(values);
    },
  });

  const onChange = (
    _event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    checked
      ? setFieldValue("availabilityStatus", "available")
      : setFieldValue("availabilityStatus", "unavailable");
  };

  const onToggleChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("appointmentDuration", item.key);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(date, "MM-dd-yyyy hh:mm aa");

      const existingObj = getExistingForecast(data?.data, formattedDate);
      setExistingSchedule(existingObj);
      setFieldValue("appointmentTime", formattedDate);
    }
  };

  const handleFreeTimeChange = (
    _event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setShowFreeTime(!!checked);
  };

  const handleHideConflictsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setHideConflicts(!!checked);
  };

  let handleDisplayConflicts = (time: Date) => {
    if (hideConflicts) {
      return "";
    }
    if (values.appointmentDuration === "30 min") {
      return "";
    }
    const formattedDate = format(time, "MM-dd-yyyy hh:mm aa");
    if (values.appointmentDuration === "1 hr") {
      const start = time.getHours();
      let className = "";
      const unavailableData = getUnAvailableData(data?.data);
      unavailableData.forEach((item) => {
        const d = new Date(item.appointmentTime);
        if (
          item?.appointmentTime?.split(" ")[0] ===
            formattedDate?.split(" ")[0] &&
          d.getHours() === start &&
          d.getTime()
        ) {
          className = "text-conflict";
        }
      });
      return className;
    }
    if (values.appointmentDuration === "2 hr") {
      const start = time.getHours();
      let className = "";
      const unavailableData = getUnAvailableData(data?.data);
      unavailableData.forEach((item) => {
        const d = new Date(item.appointmentTime);
        if (
          item?.appointmentTime?.split(" ")[0] ===
            formattedDate?.split(" ")[0] &&
          d.getHours() === start &&
          d.getTime()
        ) {
          className = "text-conflict";
        }
        if (
          item?.appointmentTime?.split(" ")[0] ===
            formattedDate?.split(" ")[0] &&
          d.getHours() === start + 1
        ) {
          className = "text-conflict";
        }
      });
      return className;
    }
    return "";
  };

  const navigate = useNavigate();

  const { isFetching, paymentData } = usePaymentMethodValidation(
    true,
    userData?.email
  );

  React.useEffect(() => {
    if (!isFetching && paymentData?.length === 0) {
      alert("Please Add Your payment details first");
      navigate("/profile", { state: true });
    }
  }, []);

  const excludeTimes = () => {
    const d: Date[] = [];
    const currentDate = values.appointmentTime?.split(" ")[0];
    const unav = getUnAvailableData(data?.data);
    unav?.forEach((i) => {
      if (i.appointmentTime.split(" ")[0] === currentDate) {
        d.push(new Date(i.appointmentTime));
      }
    });

    return d;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <StyledContent
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          width: "fit-content",
          paddingTop: "15px",
        }}
      >
        <>
          <StyledContent>
            <Label styles={labelStyles}>Appointment Calendar</Label>
            <div>
              <DatePicker
                selected={
                  values.appointmentTime
                    ? new Date(values.appointmentTime)
                    : undefined
                }
                onChange={handleDateChange}
                showTimeSelect
                minDate={new Date()}
                minTime={setMinutes(new Date(), 30)}
                maxTime={setHours(setMinutes(new Date(), 0), 18)}
                timeIntervals={getTimeInterval(values.appointmentDuration)}
                dateFormat="MM-dd-yyyy hh:mm aa"
                excludeTimes={showFreeTime ? excludeTimes() : undefined}
                wrapperClassName={mergeStyles({ input: { height: "32px" } })}
                timeClassName={handleDisplayConflicts}
                // @ts-ignore
                timeCaption={
                  <div>
                    <Toggle
                      onText={"Show Time I'm free"}
                      offText={"Show Time I'm free"}
                      checked={showFreeTime}
                      onChange={handleFreeTimeChange}
                    />
                    <Checkbox
                      label={"Hide Conflicts"}
                      checked={hideConflicts}
                      onChange={handleHideConflictsChange}
                    />
                  </div>
                }
              />
              {errors.appointmentTime && touched.appointmentTime ? (
                <div style={{ fontSize: "10px", color: "red" }}>
                  {errors.appointmentTime}
                </div>
              ) : null}
            </div>
          </StyledContent>
          <StyledContent>
            <Label styles={labelStyles}>Appointment Duration</Label>
            <Dropdown
              options={[
                { key: "30 min", text: "30 min" },
                { key: "1 hr", text: "1 hr" },
                { key: "2 hr", text: "2 hr" },
              ]}
              selectedKey={values.appointmentDuration}
              onChange={onToggleChange}
              placeholder="Select options"
              styles={{
                dropdown: { width: 350 },
              }}
            />
          </StyledContent>
          <StyledContent>
            <Label styles={labelStyles}>Availabilty</Label>
            <Toggle
              onChange={onChange}
              checked={values.availabilityStatus === "available"}
            />
          </StyledContent>
        </>
        <ButtonContainer>
          <PrimaryButton
            // @ts-ignore
            onClick={handleSubmit}
            text={"Save"}
            disabled={mutation.isLoading}
          />
        </ButtonContainer>
      </div>
      <StyledContent>
        <MyAppointments endpoint={endpoint} handleRechedule={() => {}} />
      </StyledContent>
    </StyledContent>
  );
};

export default SmeRegistrationForm;
