import { createContext } from "react";

const CurrentUserContext = createContext<
  | {
      user: { role: "string"; email: "string"; profilePath: "string" };
      setUser: React.Dispatch<any>;
    }
  | undefined
>(undefined);

export default CurrentUserContext;
