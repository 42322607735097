import * as React from "react";
import styled from "styled-components";
import appImg from "../../assets/png/appImg.png";

const StyledContainer = styled.div`
  max-width: 350px;
  color: #b2b2b2;
  font-weight: 800;
  padding-left: 50px;
  font-size: 18px;
  position: absolute;
  top: 60%;
`;

const Home = () => {
  return (
    <div>
      <img src={appImg} width={"100%"} height={"100%"} />
      <StyledContainer>
        Consulting Hub Platform is meant to connect Subject Matter Expert (SME)
        on any fields with their own customers online easily.
        <br /> Customers can get the solution to the problem from the industries
        top most SMEs.
      </StyledContainer>
    </div>
  );
};

export default Home;
