import * as Yup from "yup";

export interface UserRegistrationType {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  IssueAreas: string[];
  agreeToTerms: true;
  profilePictureLocation: string;
}

export const getInitialData = (data?: any): UserRegistrationType => ({
  email: data?.email ?? "",
  firstName: data?.firstName ?? "",
  lastName: data?.lastName ?? "",
  contactNumber: data?.contactNumber ?? "",
  IssueAreas: data?.IssueAreas ?? [],
  agreeToTerms: true,
  profilePictureLocation: "",
});

export const schema = Yup.object().shape({
  email: Yup.string().label("Email").trim().required().email(),
  firstName: Yup.string().label("First Name").trim().required().max(50),
  lastName: Yup.string().label("Last Name").trim().required().max(50),
  contactNumber: Yup.string()
    .label("Contact Number")
    .required()
    .matches(/^\d+$/, "Contact Number should be a number"),
  agreeToTerms: Yup.bool()
    .required()
    .oneOf([true], "The terms and conditions must be accepted."),
  IssueAreas: Yup.array()
    .label("Issue Areas")
    .of(Yup.string())
    .min(1)
    .required(),
});
