import * as Yup from "yup";

export interface SmeAppointmentType {
  forecastId: number;
  smeEmail: string;
  smeFirstName: string;
  smeLastName: string;
  appointmentTime: string;
  appointmentDuration: string;
  availabilityStatus: string;
}

export interface SmeBookedStatusType {
  customerFirstName: string;
  appointmentTime: string;
  appointmentId: number;
  smeFirstName: string;
  smeLastName: string;
  appointmentStatus: string;
  smeEmail: string;
  appointmentDuration: string;
  customerEmail: string;
  subjectArea: string;
}

export const getInitialData = (
  email: string,
  smeFirstName: string,
  smeLastName: string,
  appointmentData?: SmeAppointmentType
): SmeAppointmentType => ({
  forecastId: appointmentData?.forecastId ?? 0,
  smeEmail: email,
  smeFirstName: smeFirstName,
  smeLastName: smeLastName,
  appointmentTime: appointmentData?.appointmentTime ?? "",
  appointmentDuration: appointmentData?.appointmentDuration ?? "30 min",
  availabilityStatus: appointmentData?.availabilityStatus ?? "available",
});

export const getTimeInterval = (input: string) => {
  return input === "30 min" ? 30 : input === "1 hr" ? 60 : 120;
};

export const schema = Yup.object().shape({
  appointmentTime: Yup.string().label("Appointment Time").required(),
});

export const mockData = [
  {
    appointmentTime: "05-16-2022 06:00 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652618662701,
    availabilityStatus: "available",
  },
  {
    appointmentTime: "05-15-2022 03:00 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652628491517,
    availabilityStatus: "unavailable",
  },
  {
    appointmentTime: "05-15-2022 12:30 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652628491517,
    availabilityStatus: "unavailable",
  },
];

export const getUnAvailableData = (data: SmeAppointmentType[]) => {
  return data.filter((item) => item.availabilityStatus === "unavailable");
};

export const getExistingForecast = (
  data: SmeAppointmentType[],
  date: string
) => {
  return data.find((item) => item.appointmentTime === date);
};
