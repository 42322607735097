import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  PrimaryButton,
  TextField,
  Label,
  Checkbox,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./UserRegistrationForm.styles";
import {
  initialData,
  schema,
  UserRegistrationType,
} from "./UserRegistrationForm.utils";
import { getServerError, subjectAreas } from "../../utils/utils";

const UserRegistrationForm = () => {
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const navigate = useNavigate();

  const handleSuccess = (e: any) => {
    alert("User is successfully registered");
    navigate(
      `/forget-password/?email=${e.data.email}`
    )
  };

  const mutation = useMutation(
    (data: UserRegistrationType) => {
      return axios.post(
        "https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const { setFieldValue, values, touched, errors, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialData,
      validationSchema: schema,
      onSubmit: (values) => {
        mutation.mutate(values);
      },
    });

  const onChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue(
        "IssueAreas",
        item.selected
          ? [...values.IssueAreas, item.key]
          : values.IssueAreas.filter((key) => key !== item.key)
      );
    }
  };

  return (
    <div>
      <>
        <StyledContent>
          <Label styles={labelStyles}>First Name</Label>
          <TextField
            value={values.firstName}
            onChange={handleChange}
            name="firstName"
            styles={textFieldStyles}
            errorMessage={touched?.firstName ? errors?.firstName : undefined}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Last Name</Label>
          <TextField
            value={values.lastName}
            onChange={handleChange}
            name="lastName"
            styles={textFieldStyles}
            errorMessage={touched?.lastName ? errors?.lastName : undefined}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Contact Number</Label>
          <TextField
            value={values.contactNumber}
            onChange={handleChange}
            type="number"
            name="contactNumber"
            styles={textFieldStyles}
            min="0"
            errorMessage={
              touched?.contactNumber ? errors?.contactNumber : undefined
            }
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Email</Label>
          <TextField
            value={values.email}
            onChange={handleChange}
            name="email"
            styles={textFieldStyles}
            errorMessage={touched?.email ? errors?.email : undefined}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Issue Areas</Label>
          <Dropdown
            options={subjectAreas}
            multiSelect={true}
            selectedKeys={values.IssueAreas}
            onChange={onChange}
            placeholder="Select options"
            styles={{
              dropdown: { width: 350 },
            }}
            // @ts-ignore
            errorMessage={touched?.IssueAreas ? errors?.IssueAreas : undefined}
          />
        </StyledContent>
        <StyledContent style={{ alignItems: "baseline" }}>
          <Label styles={labelStyles}>I agree to</Label>
          <Checkbox
            checked={values.agreeToTerms}
            onChange={handleChange}
            name="agreeToTerms"
          />
          <Label styles={{ root: { fontSize: "16px", fontWeight: "bold" } }}>
            Terms
          </Label>
          <TextField
            readOnly
            value={
              "I understand that this CHP platform is not responsible for any consequences that could raise out this consulting proceeds. I agree to all terms and conditions of CHP Platform owned by USI. I won't directly connect with any of the SMEs that I consult here in CHP."
            }
            multiline={true}
            styles={{ root: { width: "270px", marginLeft: "10px" } }}
            errorMessage={
              touched?.agreeToTerms ? errors?.agreeToTerms : undefined
            }
          />
        </StyledContent>
      </>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={handleSubmit}
          text={"Register"}
          disabled={mutation.isLoading}
        />
      </ButtonContainer>
    </div>
  );
};

export default UserRegistrationForm;
