import * as React from "react";
import styled from "styled-components";
import appImg from "../../assets/png/appImg.png";

const StyledContainer = styled.div`
  max-width: 350px;
  color: #b2b2b2;
  font-weight: 800;
  padding-left: 50px;
  font-size: 18px;
  position: absolute;
  top: 60%;
`;

const Contactus = () => {
  return (
    <div>


        <br /> contact: director@unigeniussoft.com ph: 469-777-6063
        
    </div>
  );
};

export default Contactus;
