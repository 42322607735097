import axios from "axios";
import * as React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CurrentUserContext from "../../CurrentUserContext";
import usePaymentMethodValidation from "../../utils/usePaymentMethodValidation";
import CustomerAppointmentForm from "./CustomerAppointmentForm/CustomerAppointmentForm";
import MyAppointments from "./MyAppointments/MyAppointments";

const endpoint = "appointments";

const CustomerAppointment = () => {
  const context = React.useContext(CurrentUserContext);
  const [callBackObj, setCallBackObj] = React.useState({});
  const email = context?.user?.email || "";
  const role = context?.user?.role;

  const navigate = useNavigate();
  const { data } = useQuery("getSMEDetail", () => {
    return axios.get(
      `https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/${email}`
    );
  });

  const { isFetching, paymentData } = usePaymentMethodValidation(false, email);

  React.useEffect(() => {
    if (!isFetching && paymentData?.length === 0) {
      alert("Please Add Your payment details first");
      navigate("/profile", { state: true });
    }
  }, [isFetching, paymentData?.length]);

  const handleRechedule = (item: any) => {
    setCallBackObj(item);
  };

  return paymentData?.length > 0 ? (
    <>
      {data?.data && (
        <CustomerAppointmentForm
          resetAll={() => setCallBackObj({})}
          userData={data?.data}
          rescheduleObj={callBackObj}
        />
      )}
      <MyAppointments
        endpoint={endpoint}
        handleRechedule={(item) => handleRechedule(item)}
        role={role}
      />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default CustomerAppointment;
