import * as React from "react";
import styled from "styled-components";
import appImg from "../../assets/png/appImg.png";

const StyledContainer = styled.div`
  color: #b2b2b2;
  font-weight: 800;
  padding-left: 50px;
  font-size: 18px;
  position: absolute;
  top: 10%;
  left: 40%;
  padding: 10px;
  border: 1px solid #c8c8c8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow-y: auto;
  max-height: 75%;
`;

const Layout = (props: React.PropsWithChildren<{}>) => {
  return (
    <div>
      <img src={appImg} width={"100%"} height={"100%"} alt="img" />
      <StyledContainer>{props.children}</StyledContainer>
    </div>
  );
};

export default Layout;
