import {
  ChoiceGroup,
  IChoiceGroupOption,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import axios, { AxiosError } from "axios";
import * as React from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useMutation, useQuery } from "react-query";
import CurrentUserContext from "../../CurrentUserContext";
import { getServerError } from "../../utils/utils";
import PaymentSettingForm from "./PaymentSettingForm/PaymentSettingForm";
import { PaymentSettingType } from "./PaymentSettingForm/PaymentSettingForm.utils";

const public_key: string = process.env.REACT_APP_STRIPE_PK || '';
const stripePromise = loadStripe(public_key);

const UserProfile = () => {
  const context = React.useContext(CurrentUserContext);
  const email = context?.user.email;
  
  const defaultSelection = { value: false };
  const [changed, setChanged] = React.useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = React.useState<
    string | undefined
  >(undefined);
  const [initialData, setInitialData] = React.useState<any>(undefined);
  const [disabled, setDisabled] = React.useState<boolean | undefined>(
    undefined
  );
  const { data, isLoading, isError } = useQuery("getPayments", () => {
    return axios.get(
      `https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod/paymentmethods/${email}`,
      {
        headers: {},
      }
    );
  });

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Payment Method is successfully deleted");
  };

  const mutation = useMutation(
    () => {
      return axios.delete(
        `https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod/${selectedPayment}`
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );
  const handleChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: any
  ) => {
    setChanged(true);
    setSelectedPayment(option?.key ? String(option.key) : undefined);
    const selectedData = data?.data?.find(
      (item: { id: string }) =>
        item.id === String(option?.key)
    );

    data?.data.find((o: any) => {
      if (o.isDefault === true) {
        defaultSelection.value = true
      }
    });
    setInitialData({
      makeAsDefault: selectedData?.isDefault,
      cardNumber: selectedData?.card?.last4,
      paymentMethod: selectedData?.paymentMethod,
      cardExpiry: selectedData?.cardExpiry,
      nameOnCard: selectedData?.nameOnCard,
      securityCode: selectedData?.securityCode,
      customerEmail: selectedData?.customerEmail,
      cardType: selectedData?.cardType,
      payment_method_id: selectedData?.id,
      zipCode: selectedData?.zipCode,
      defaultselection: defaultSelection?.value
    });
    setDisabled(true);
  };

  const handleDelete = () => {
    const selectedData = data?.data?.find(
      (item: { id: string }) =>
        item.id === selectedPayment
    );
    if (selectedData.isDefault) {
      alert("You can not delete default payment method");
    } else {
      mutation.mutate();
    }
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      <div>Something went wrong</div>;
    }

    const options: any = data?.data?.map(
      (item: any) => ({
        key: String(item.id),
        text: `${item.card.brand}xxxxxx${String(item.card.last4)}`,
        data: item,
      })
    );
    //sort options by id
    const sortedOptions = options?.sort((a:any, b:any) => Number(a.data.created) - Number(b.data.created));
    return sortedOptions?.length ? (
      <div
        style={{
          display: "flex",
          height: "fit-content",
          backgroundColor: "white",
          padding: "10px",
          gap: "10px",
        }}
      >
        <ChoiceGroup
          options={sortedOptions}
          onChange={handleChange}
          selectedKey={selectedPayment}
        />
        <div>
          <PrimaryButton
            text="Delete"
            onClick={handleDelete}
            disabled={!selectedPayment || mutation.isLoading}
            styles={{ root: { marginBottom: "5px" } }}
          />
          {mutation.isLoading && <Spinner />}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const handleReset = () => {
    setDisabled(undefined);
    setInitialData(undefined);
    setSelectedPayment(undefined);
  };

  return (
    <div style={{ display: "flex" }}>
      {
        stripePromise &&
        <Elements stripe={stripePromise}>
          <PaymentSettingForm
            initialData={initialData}
            disabled={disabled}
            onReset={handleReset}
            changed={changed}
            setChanged={setChanged}
          />
        </Elements>
      }
      {getContent()}
    </div>
  );
};

export default UserProfile;
