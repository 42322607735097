import * as Yup from "yup";

export interface SmeRegistrationType {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  areaOfExpertise: string[];
  agreeToTerms: boolean;
  backgroundProficiency: string;
  consultationFee?: number;
}

export const initialData: SmeRegistrationType = {
  email: "",
  firstName: "",
  lastName: "",
  contactNumber: "",
  areaOfExpertise: [],
  agreeToTerms: false,
  backgroundProficiency: "",
  consultationFee: undefined,
};

export const schema = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .trim()
    .required()
    .email()
    .test("email", "User is already registered", (value, context) => {
      // @ts-ignore
      const a = context.options.context?.smes?.find(
        // @ts-ignore
        (item) => item.email.toLowerCase() === value?.toLowerCase()
      );
      return a ? false : true;  
    }),
  firstName: Yup.string().label("First Name").trim().required().max(50),
  lastName: Yup.string().label("Last Name").trim().required().max(50),
  contactNumber: Yup.string()
    .label("Contact Number")
    .required()
    .matches(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, "Contact Number should be a valid number"),
  agreeToTerms: Yup.bool()
    .required()
    .oneOf([true], "The terms and conditions must be accepted."),
  areaOfExpertise: Yup.array()
    .label("Area of expertise")
    .of(Yup.string())
    .min(1)
    .required(),
  backgroundProficiency: Yup.string().label("Background and Proficiency").trim().required().max(250),
  consultationFee: Yup.number().label("Consultation Fee").required().positive(),
});

export const isSmeAlreadyRegistered = (
  email: string,
  smes?: SmeRegistrationType[]
) => {
  return smes?.find((item) => item.email.toLowerCase() === email.toLowerCase());
};
