import axios from "axios";
import { useQuery } from "react-query";
import { useMemo } from "react";

const userPaymentURL =
  "https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod/paymentmethods";
const smePaymentURL =
  "https://5luv8fybqj.execute-api.us-east-2.amazonaws.com/Prod/sme/paymentmethods";

const usePaymentMethodValidation = (isSME: boolean, email: string) => {
  const paymentEndPoint = useMemo(() => {
    return isSME ? `${smePaymentURL}/${email}` : `${userPaymentURL}/${email}`;
  }, [email, isSME]);

  const { data, isFetching } = useQuery("verifyPayment", () => {
    return axios.get(paymentEndPoint);
  });

  return { paymentData: data?.data, isFetching };
};

export default usePaymentMethodValidation;
