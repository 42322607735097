import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { getServerError } from "../../../utils/utils";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./PersonalDetails.styles";
import axios, { AxiosError } from "axios";
import {
  getInitialData,
  schema,
  UserRegistrationType,
} from "./PersonalDetails.utils";
import CurrentUserContext from "../../../CurrentUserContext";

const PersonalDetails = () => {
  const context = React.useContext(CurrentUserContext);
  const email = context?.user.email;
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [newIssueArea, setNewIssueArea] = React.useState<string>("");
  const [isUploading, setIsUploading] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [dropDownOptions, setDropdownOptions] = React.useState<
    IDropdownOption[]
  >([]);
  const [image, setImage] = React.useState(null);

  const handleImageUploadChange = (e: any) => {
    setImage(e?.target?.files?.[0]);
  };
  const handleNewIssueAreaChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setNewIssueArea(newValue ?? "");
  };

  const handleAddButtonClick = () => {
    if (!newIssueArea.trim()) {
      return;
    }
    const newOptions: IDropdownOption[] = [
      ...dropDownOptions,
      { key: newIssueArea, text: newIssueArea },
    ];
    setDropdownOptions(newOptions);

    setFieldValue("IssueAreas", [...values.IssueAreas, newIssueArea]);
    setNewIssueArea("");
  };

  const handleUploadImage = () => {
    const email = context?.user?.email || "";
    setIsUploading(true);
    const formData = new FormData();
    image && formData.append("file", image);
    formData.append("email", email);
    imageMutate.mutate(formData);
  };
  const handleEditButtonClick = () => {
    setCanEdit(true);
  };

  const handleCancelButtonClick = () => {
    setCanEdit(false);
    resetForm();
  };

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = (e: any) => {
    alert("User details successfully updated");
  };

  const handleImageSuccess = (responce: any) => {
    setIsUploading(false);
    const imagePath = responce?.data?.filePath;
    setUrl(imagePath);

    imagePath && context?.setUser({ ...context?.user, profilePath: imagePath });
    alert("Your profile Photo has been Uploaded successfully");
  };

  const imageMutate = useMutation(
    (formData: any) => {
      return axios.post(
        `https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/uploadItem`,
        formData,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleImageSuccess }
  );
  // let imageUrl = imageMutate?.data?.data?.filePath;

  const handleQueryComplete = (data: any) => {
    const options: IDropdownOption[] =
      data?.data?.IssueAreas?.map((item: string) => ({
        key: item,
        text: item,
      })) ?? [];

    setDropdownOptions(options);
  };

  const { data, isLoading, isError } = useQuery(
    "getUserDetail",
    () => {
      return axios.get(
        `https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/${email}`
      );
    },
    { onSuccess: handleQueryComplete }
  );

  const mutation = useMutation(
    (data: UserRegistrationType) => {
      return axios.post(
        "https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: getInitialData(data?.data),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutation.mutate({ ...values, profilePictureLocation: url });
    },
  });

  const onChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue(
        "IssueAreas",
        item.selected
          ? [...values.IssueAreas, item.key]
          : values.IssueAreas.filter((key) => key !== item.key)
      );
    }
  };

  if (isLoading) {
    return <div>Loading....</div>;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      {!canEdit && (
        <DefaultButton
          text="Edit"
          onClick={handleEditButtonClick}
          styles={{ root: { marginTop: "20px" } }}
        />
      )}
      <>
        <StyledContent></StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>First Name</Label>
          <TextField
            value={values.firstName}
            onChange={handleChange}
            name="firstName"
            styles={textFieldStyles}
            errorMessage={touched?.firstName ? errors?.firstName : undefined}
            readOnly={!canEdit}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Last Name</Label>
          <TextField
            value={values.lastName}
            onChange={handleChange}
            name="lastName"
            styles={textFieldStyles}
            errorMessage={touched?.lastName ? errors?.lastName : undefined}
            readOnly={!canEdit}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Email</Label>
          <TextField
            value={values.email}
            onChange={handleChange}
            name="email"
            styles={textFieldStyles}
            errorMessage={touched?.email ? errors?.email : undefined}
            readOnly={true}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Contact Number</Label>
          <TextField
            value={values.contactNumber}
            onChange={handleChange}
            name="contactNumber"
            styles={textFieldStyles}
            errorMessage={
              touched?.contactNumber ? errors?.contactNumber : undefined
            }
            readOnly={!canEdit}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Issue Areas</Label>
          <div>
            <Dropdown
              options={dropDownOptions}
              multiSelect={true}
              selectedKeys={values.IssueAreas}
              onChange={onChange}
              placeholder="Select options"
              disabled={!canEdit}
              styles={{
                dropdown: { width: 350 },
                title: {
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                },
              }}
              // @ts-ignore
              errorMessage={
                touched?.IssueAreas ? errors?.IssueAreas : undefined
              }
            />
            {canEdit && (
              <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
                <TextField
                  value={newIssueArea}
                  onChange={handleNewIssueAreaChange}
                  styles={{ root: { width: 150 } }}
                />
                <DefaultButton onClick={handleAddButtonClick} text={"Add"} />
              </div>
            )}
          </div>
        </StyledContent>
        <StyledContent style={{ alignItems: "center" }}>
          <Label styles={labelStyles}>
            {canEdit && "Profile Photo Upload"}
          </Label>
          {canEdit && (
            <input
              type="file"
              onChange={handleImageUploadChange}
              width={50}
              accept="image/png, image/gif, image/jpeg"
              disabled={!canEdit}
            />
          )}
          <StyledContent style={{ flexDirection: "row", alignItems: "center" }}>
            {/* {(canEdit && image) && <img src={URL.createObjectURL(image)} style={{ width: 70, padding: 10 }} />} */}

            {canEdit && (
              <DefaultButton
                style={{ width: "90px" }}
                onClick={handleUploadImage}
                text={isUploading ? "Uploading..." : "Upload"}
                disabled={!image || isUploading}
              />
            )}
          </StyledContent>
        </StyledContent>
      </>

      {canEdit && (
        <ButtonContainer>
          <PrimaryButton
            // @ts-ignore
            onClick={handleSubmit}
            text={"Save"}
            disabled={mutation.isLoading}
          />
          <DefaultButton
            onClick={handleCancelButtonClick}
            text={"Cancel"}
            styles={{ root: { marginLeft: "20px" } }}
          />
        </ButtonContainer>
      )}
    </div>
  );
};

export default PersonalDetails;
