import * as React from "react";
import { useNavigate } from "react-router-dom";
import CurrentUserContext from "../../CurrentUserContext";


const SignOut = () => {
  const context = React.useContext(CurrentUserContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    context?.setUser({});
    navigate(`/sign-in`);
  }, []);

  return <div />;
};

export default SignOut;
