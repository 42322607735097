import { IPivotStyles, mergeStyles, Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledContainer = styled.div`
  .ms-Pivot-icon {
    padding: 0px 20px 0px 50px;
  }
  position: relative;
  height: 70px;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const selectedRoute = params.pathname.substring(1);
  const onLinkClick = (item?: PivotItem) => {
    if (item?.props.itemKey) {
      navigate(`/${item.props.itemKey}`);
    }
  };

  const navbarStyles: Partial<IPivotStyles> = {
    root: { backgroundColor: "black", textAlign: "right", height: "70px" },
    link: {
      backgroundColor: "black",
      color: "white",
      height: "70px",
      "&:hover": {
        backgroundColor: "grey",
        height: "70px",
      },
    },
    linkIsSelected: selectedRoute.includes("forget-password")
      ? {
          backgroundColor: "black !important",
          color: "white !important",
          height: "70px",
          "&:hover": {
            backgroundColor: "grey",
            height: "70px",
          },
        }
      : {
          backgroundColor: "white !important",
          color: "black !important",
          height: "70px",
        },
  };

  return (
    <StyledContainer>
      <Pivot
        aria-label="OnChange Pivot Example"
        linkSize="large"
        linkFormat="tabs"
        onLinkClick={onLinkClick}
        defaultSelectedKey={selectedRoute}
        styles={navbarStyles}
      >
        <PivotItem headerText="Home" itemKey="home" />
        <PivotItem headerText="User Registration" itemKey="user-registration" />
        <PivotItem headerText="SME Registrations" itemKey="sme-registration" />
        <PivotItem headerText="Sign In" itemKey="sign-in" />
        <PivotItem headerText="Contact Us" itemKey="contactus" />
        <PivotItem
          headerText=""
          itemIcon={"search"}
          className={mergeStyles({ paddingLeft: "50px" })}
        />
      </Pivot>
    </StyledContainer>
  );
};

export default Navbar;
