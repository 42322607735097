import * as Yup from "yup";

export const columns = [
  {
    key: "column1",
    name: "Feedback",
    fieldName: "feedback",
    isResizable: true,
    minWidth: 200,
    maxWidth: 200,
  },
  {
    key: "column2",
    name: "Rating",
    fieldName: "rating",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
  },
  {
    key: "column3",
    name: "Issue Area",
    fieldName: "issue_area",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
  },
  {
    key: "column4",
    name: "Comment",
    fieldName: "comment",
    isResizable: true,
    minWidth: 200,
    maxWidth: 200,
  },
];

export interface SmeFeedbackType {
  reviewId: number;
  comment: string;
  smeEmail: string;
  feedback: string;
  rating: string;
  issueArea: string;
}

export const getInitialData = (
  reviewId?: number,
  smeEmail?: string,
  rating?: string,
  feedback?: string,
  issueArea?: string
): SmeFeedbackType => ({
  reviewId: reviewId ?? 0,
  smeEmail: smeEmail ?? "",
  comment: "",
  rating: rating ?? "",
  feedback: feedback ?? "",
  issueArea: issueArea ?? "",
});

export const schema = Yup.object().shape({
  comment: Yup.string().label("Comment").required(),
});
