import { PrimaryButton, Spinner } from "@fluentui/react";
import axios, { AxiosError } from "axios";
import * as React from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import CurrentUserContext from "../../../CurrentUserContext";
import { getServerError } from "../../../utils/utils";
import { CustomerAppointmentType } from "../CustomerAppointmentForm/CustomerAppointmentForm.utils";
import Pagination from "../../Pagination";

const getGraceTimeStamp = (appTimeStamp: number, minutes: number) => {
  return new Date(appTimeStamp + minutes * 60000).getTime();
};
const currentTimeStamp = new Date().getTime();

interface MyAppointmentsProps {
  endpoint: string;
  handleRechedule: (item: any) => void;
  role?: string;
}
let appointmentId: string;
let pageSize = 10;

const MyAppointments = ({
  endpoint,
  handleRechedule,
  role,
}: MyAppointmentsProps) => {
  const navigate = useNavigate();
  const context = React.useContext(CurrentUserContext);
  const email = context?.user.email;
  const [isAppointmentInit, setIsAppointmentInit] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState(0);
  const [showAppointmentHistory, setShowAppointmentHistory] = React.useState<boolean>(false);
  const [filteredAppointments, setFilteredAppointments] = React.useState([]);
  const [historyAppointments, setHistoryAppointments] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const isSME = React.useMemo(() => {
    return endpoint?.includes("sme");
  }, [endpoint]);

  const handleErrorCancel = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const { data, isLoading } = useQuery("getAppointments", () => {
    return axios.get(
      `https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/${endpoint}/${email}`
    );
  });

  const appointmentStatus: any = {
    canceled: "canceled",
    finished: "finished",
  };

  React.useEffect(() => {
    const filteredAppointments = data?.data?.filter((appointment: any) => {
      const currentTime = new Date();
      const today = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate()
      );
      const appointmentTime = new Date(appointment.appointmentTime);

      return (
        !appointmentStatus[appointment?.appointmentStatus?.toLowerCase()] &&
        appointmentTime >= today
      );
    });

    setFilteredAppointments(filteredAppointments);

    const historyAppointments = data?.data?.filter((appointment: any) => {
      const currentTime = new Date();
      const today = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate()
      );
      const appointmentTime = new Date(appointment.appointmentTime);
      return (
        appointmentStatus[appointment?.appointmentStatus?.toLowerCase()] ||
        appointmentTime < today
      );
    });
    setHistoryAppointments(historyAppointments);
  }, [data?.data]);

  const currentHistoryAppointments = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return historyAppointments?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, historyAppointments]);

  const handleSuccessCancel = () => {
    alert("Appointment Canceled");
  };

  const mutationCancel = useMutation(
    (data: CustomerAppointmentType) => {
      return axios.post(
        "https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleErrorCancel, onSuccess: handleSuccessCancel }
  );

  const handleDeleteClick = (
    e: React.FormEvent<HTMLElement | HTMLInputElement>,
    item: any
  ) => {
    e.preventDefault();
    let newObj = {
      ...item,
      appointmentStatus: "Canceled",
    };
    mutationCancel.mutate(newObj);
  };
  const handleChangeInput = (item: any, isHistoryView: boolean) => {
    setSelectedAppointment(item.appointmentId);
    if (!isHistoryView) {
      handleAppointmentSelect(item);
    } else {
      navigate(`/appointmentdetailspage/${item.appointmentId}`);
    }
  };

  function convertToMinutes(timeString: string) {
    if (timeString) {
    const [num, unit] = timeString.split(" ");
    const numInt = parseInt(num);
    if (unit === "min") {
      return numInt;
    } else if (["hour", "hr", "hrs", "hours", "Hour"]?.includes(unit)) {
      return numInt * 60;
    } else {
      throw new Error("Invalid time string: " + timeString);
    }
    }
    return 0;
  }

  const RenderOptions = ({ appointmentList, isHistoryView }: any) => {
    return appointmentList?.map(
      !isSME
        ? (item: {
          smeFirstName: string;
          smeLastName: string;
          appointmentId: number;
          appointmentTime: string;
          appointmentDuration: string;
          customerFirstName: string;
        }) => {
          const appTimeStamp = new Date(item?.appointmentTime).getTime();
          const convertedTime = item?.appointmentDuration
            ? convertToMinutes(item?.appointmentDuration)
            : 0;

          const joinTimeStamp = getGraceTimeStamp(
            appTimeStamp,
            convertedTime
          );
          const isPastTime =
            joinTimeStamp <= currentTimeStamp && !isHistoryView;

          return (
            <tr key={item?.appointmentId}>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 3,
                    paddingBottom: 3,
                    color: isPastTime ? "grey" : "black",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <input
                    onChange={() =>
                      !isPastTime && handleChangeInput(item, isHistoryView)
                    }
                    checked={item.appointmentId === selectedAppointment}
                    type="radio"
                    value={item.appointmentId}
                  />
                  <label
                    onClick={() =>
                      !isPastTime && handleChangeInput(item, isHistoryView)
                    }
                    style={{
                      marginLeft: 3,
                      cursor: !isPastTime ? "pointer" : "default",
                      fontWeight: "400",
                    }}
                  >
                    Appointment with {item?.smeFirstName}{" "}
                  </label>
                  {!isHistoryView && (
                    <>
                      <i
                        onClick={(e) => !isPastTime && handleRechedule(item)}
                        className="fa fa-calendar"
                        style={{
                          cursor: !isPastTime ? "pointer" : "default",
                          marginRight: 10,
                          marginLeft: 10,
                        }}
                      />
                      <i
                        onClick={(e) =>
                          !isPastTime && handleDeleteClick(e, item)
                        }
                        className="fa fa-times"
                        style={{
                          cursor: !isPastTime ? "pointer" : "default",
                        }}
                      />
                    </>
                  )}
                </div>
              </td>
              {isHistoryView && (
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 3,
                      paddingBottom: 3,
                      color: "black",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <label
                      style={{
                        marginLeft: 3,
                        fontWeight: "400",
                      }}
                    >
                      {item?.appointmentTime}
                    </label>
                  </div>
                </td>
              )}
            </tr>
          );
        }
        : (item: {
          customerFirstName: string;
          appointmentId: number;
          appointmentTime: string;
          appointmentDuration: string;
        }) => {
          const appTimeStamp = new Date(item?.appointmentTime).getTime();

          const isPastTime = appTimeStamp <= currentTimeStamp;

          return (
            <tr key={item?.appointmentId}>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 3,
                    paddingBottom: 3,
                    color: !isPastTime && !isHistoryView ? "grey" : "black",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <input
                    onChange={() =>
                      (!isPastTime || isHistoryView) &&
                      handleChangeInput(item, isHistoryView)
                    }
                    checked={item.appointmentId === selectedAppointment}
                    type="radio"
                    value={item.appointmentId}
                  />
                  <label
                    onClick={() =>
                      (isPastTime || isHistoryView) &&
                      handleChangeInput(item, isHistoryView)
                    }
                    style={{
                      marginLeft: 3,
                      cursor:
                        !isPastTime || isHistoryView ? "pointer" : "default",
                      fontWeight: "400",
                    }}
                  >
                    Appointment with {item?.customerFirstName}
                  </label>
                  {!isHistoryView && (
                    <>
                      <i
                        onClick={(e) =>
                          !isPastTime && handleDeleteClick(e, item)
                        }
                        className="fa fa-times"
                        style={{
                          cursor: !isPastTime ? "pointer" : "default",
                          marginLeft: 10,
                        }}
                      />
                    </>
                  )}
                </div>
              </td>
              {isHistoryView && (
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 3,
                      paddingBottom: 3,
                      color: "black",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <label
                      style={{
                        marginLeft: 3,
                        fontWeight: "400",
                      }}
                    >
                      {item?.appointmentTime}
                    </label>
                  </div>
                </td>
              )}
            </tr>
          );
        }
    );
  };

  const handleAppointmentSelect = (option?: any) => {
    const {
      appointmentId,
      smeFirstName,
      smeLastName,
      customerFirstName,
      smeEmail,
      customerEmail,
      appointmentTime,
      appointmentDuration,
    } = option || {};
    const smeName = `${smeFirstName} ${smeLastName}`;
    const data = {
      id: appointmentId,
      custName: customerFirstName,
      smename: smeName,
      smeEmail: smeEmail,
      custEmail: customerEmail,
      isFromSMEWindow: role === "customer" ? false : true,
    };

    const appTimeStamp = new Date(appointmentTime).getTime();
    const userCurrentTime = new Date().getTime();
    const convertedTime = convertToMinutes(appointmentDuration);
    const joinTimeStamp = getGraceTimeStamp(appTimeStamp, convertedTime);

    if (
      userCurrentTime + 600000 >= appTimeStamp &&
      userCurrentTime + 600000 <= joinTimeStamp
    ) {
      setIsAppointmentInit(true);
      mutation.mutate(data);
      return;
    }
    alert(`Your Appointment is at ${appointmentTime}`);
  };

  const handleError = (e: any) => {
    setIsAppointmentInit(false);
  };
  const handleSuccess = (e: any) => {
    let { id } = JSON.parse(e.config.data);

    setIsAppointmentInit(false);

    if (e?.status === 200) {
      localStorage.setItem("sessionChannelId", id);

      navigate(`/appointmentdetailspage/${id}`);
    }
  };

  const mutation = useMutation(
    (data: any) => {
      return axios.post(
        "https://1jvc56apqe.execute-api.us-east-2.amazonaws.com/Prod/init",
        data,
        {
          headers: {
            "content-Type": "application/json",
          },
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  if (isLoading) {
    return <div>Loading....</div>;
  }

  return (
    <div
      style={
        !isSME
          ? {
            width: "fit-content",
            position: "absolute",
            right: "11%",
            top: "8%",
            display: "flex",
          }
          : {
            width: "fit-content",
            right: "11%",
            top: "8%",
            display: "flex",
          }
      }
    >
      <div>
        {" "}
        {/* {data?.data.length ? ( */}
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingBottom: "5px",
            color: "black",
          }}
        >
          My Appointments
        </div>
        {/* ) : null} */}
        {filteredAppointments?.length <= 0 ? (
          <div>
            <p> No Appointment found</p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "60vh",
              backgroundColor: "white",
              padding: "10px",
              gap: "10px",
              overflow: "scroll",
            }}
          >
            {isAppointmentInit ? (
              <>
                Innitializing Your Appointment
                <Spinner />
              </>
            ) : (
              <div
                style={{
                  minWidth: 300,
                  width: "auto",
                }}
              >
                <RenderOptions
                  appointmentList={filteredAppointments}
                  isHistoryView={false}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div style={{ marginLeft: "15px" }}>
        <>
          {showAppointmentHistory ? (
            <>
              {/* {data?.data.length ? ( */}
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  color: "black",
                }}
              >
                Appointment History
              </div>
              {/* ) : null} */}
              {currentHistoryAppointments?.length <= 0 ? (
                <div>
                  <p> No Appointment found</p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "60vh",
                    backgroundColor: "white",
                    padding: "10px",
                    gap: "10px",
                    overflow: "scroll",
                  }}
                >
                  <div
                    style={{
                      minWidth: 300,
                      width: "auto",
                    }}
                  >
                    <table>
                      <tr>
                        <th>Appointments</th>
                        <th>Date/Time</th>
                      </tr>
                      <RenderOptions
                        appointmentList={currentHistoryAppointments}
                        isHistoryView={true}
                      />
                    </table>
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={historyAppointments?.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {!!historyAppointments?.length && (
                <PrimaryButton
                  onClick={() => setShowAppointmentHistory(true)}
                  text={"Show Appointment History"}
                />
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default MyAppointments;