import styled from "styled-components";

export const Container = styled.div`
    width: 90vw;
    height: 700px;

`
export const StyledContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left : 8%;
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
  padding-left: 20px;
`;

export const SmeName = styled.div`
    color: black;
    display: flex;
    margin-top:-20px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
    font-size:2rem;
`
export const VideoCall = styled.div`

    color: black;
    text-align: center;
`
export const AppTimeContainer = styled.div`
        display: flex;
        flex-direction: column;
        fontSize: 1rem;
        color: black;
`
export const TextFieldsContainer = styled.div`
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: flex-end;
`
export const TextArea = styled.textarea`
    height: 200px;
    width: 350px;

`
export const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:30%;
`
export const AppSmeName = styled.h4` 
    font-weight: 500; 
    margin:20px;
    font-size : 110%;
    margin-left : 120px;
`

export const DetailPanel = styled.div`

    background-color: white;
    width: 870px;
    margin: 40px auto;


`