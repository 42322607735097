import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AdminAuthLayout from "../Layout/AdminLayout";
import SignOut from "../SignOut/SignOut";
import AdminReports from "../AdminLogin/AdminReports.jsx";
import ExpenseReport from "../AdminLogin/ExpenseReports";

const AdminAuthRoute = () => {
  return (
    <Routes>
      <Route
        path="/reports"
        element={
          <AdminAuthLayout>
            <AdminReports />
          </AdminAuthLayout>
        }
      />

      <Route
        path="sign-out"
        element={
          <AdminAuthLayout>
            <SignOut />
          </AdminAuthLayout>
        }
      />
      <Route
        path="expense-reports"
        element={
          <AdminAuthLayout>
            <ExpenseReport />
          </AdminAuthLayout>
        }
      />
      <Route
        element={<AdminAuthLayout>Page Not found</AdminAuthLayout>}
        path="*"
      />
    </Routes>
  );
};

export default AdminAuthRoute;
