import * as React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  font-weight: 800;
  padding-left: 50px;
  font-size: 18px;
  position: absolute;
  top: 13%;
  padding: 10px;
  border: 1px solid #c8c8c8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow-y: auto;
  max-height: 75%;
  width: 98%;
`;

const AdminLayout = (props: React.PropsWithChildren<{}>) => {
  return <StyledContainer>{props.children}</StyledContainer>;
};

export default AdminLayout;
