import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import {
  PrimaryButton,
  TextField,
  Label,
  Checkbox,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./PaymentSettingForm.styles";
import {
  schema,
  PaymentSettingType,
  PaymentMethodId,
  CardType,
  getInitialData,
} from "./PaymentSettingForm.utils";
import { getServerError } from "../../../utils/utils";
import CurrentUserContext from "../../../CurrentUserContext";
import {
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';

interface PaymentSettingsForm {
  initialData?: PaymentSettingType;
  disabled?: boolean;
  changed?: boolean;
  setChanged?: any;
  onReset?: () => void;
}

const PaymentSettingForm = ({
  initialData,
  disabled,
  onReset,
  changed,
  setChanged
}: PaymentSettingsForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const context = React.useContext(CurrentUserContext);
  const handleError = (error: AxiosError) => {
    alert("Something went wrong. Please try again later");
  };
  const [stripeToken, setStripeToken] = React.useState<string>("");
  const email = context?.user.email;
  const defaultSelection = { value: false };
  const handleSuccess = () => {
    alert("Payment Method is successfully added and set as the default payment method.");
  };
  const handleDefaultError = () => {
    alert("Something went wrong. Please try again later");
  };

  const { data, isLoading, isError } = useQuery("getPayments", () => {
    return axios.get(
      `https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod/paymentmethods/${email}`,
      {
        headers: {},
      }
    );
  });

  const mutation = useMutation(
    (data: PaymentSettingType) => {
      return axios.post(
        "https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const defaultMutation = useMutation(
    (data) => {
      return axios.post(
        "https://g1vdjcanm1.execute-api.us-east-2.amazonaws.com/Prod/makedefault",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleDefaultError, onSuccess: handleSuccess }
  );
  data?.data.find((o: any) => {
    if (o.makeAsDefaultMethod === true) {
      defaultSelection.value = true
    }
  });  
  const handleDefaultPayment = () => {
    const cardData: any = initialData;

    if (cardData?.payment_method_id !== "") {
      const data: any = { makeAsDefault: true, payment_method_id: cardData?.payment_method_id, customerEmail: context?.user.email };
      defaultMutation.mutate(data);
    }
    else {
      alert("Please enter valid card details");
    }
  }
  const generateStripeToken = async () => {
    const cardElement = elements?.getElement(CardElement);
    if (cardElement) {
      const { token, error }: any = await stripe?.createToken(cardElement);
      if (token) {
        setStripeToken(token.id);
        handleAddPayment(token.id);
      }
      else {
        alert(error?.message);
      }
    }
  }
  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialData ?? getInitialData(context?.user.email),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.securityCode = String(values.securityCode);
      values.cardNumber = String(values.cardNumber);
      values.zipCode = String(values.zipCode);

      values.paymentMethodId = values.paymentMethodId
        ? values.paymentMethodId
        : Date.now();
      if (defaultSelection.value == true || values.makeAsDefault == true) {
        mutation.mutate(values);
      }
      else {
        alert("Please select a default payment method");
      }
    },
  });

  const handleAddPayment = (value: string) => {
    if (value !== "") {
      const data: any = { card_token: value, customerEmail: values.customerEmail };
      if (values.makeAsDefault == true) {
        mutation.mutate(data);
      }
      else {
        mutation.mutate(data);
        // alert("Please select a default payment method");
      }
    }
    else {
      alert("Please enter valid card details");
    }
  }
  const onPaymentIdChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("paymentMethod", item?.key);
    }
  };

  const onCardTypeChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("cardType", item?.key);
    }
  };

  const handleChangeDefaultMethod = (event: any) => {
    setFieldValue("makeAsDefault", event?.target.checked);
  };

  const handleReset = () => {
    onReset?.();
    resetForm();
  };

  return (
    <div>
      <>
        <StyledContent>
          <Label styles={labelStyles}>Payment Type</Label>
          <Dropdown
            options={[
              {
                key: PaymentMethodId.CreditCard,
                text: PaymentMethodId.CreditCard,
              },
              {
                key: PaymentMethodId.DebitCard,
                text: PaymentMethodId.DebitCard,
              },
            ]}
            selectedKey={values.paymentMethod}
            onChange={onPaymentIdChange}
            placeholder="Select options"
            styles={{
              dropdown: { width: 350 },
            }}
            errorMessage={
              touched?.paymentMethod ? errors?.paymentMethod : undefined
            }
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent style={{ alignItems: "center" }}>
          <Label styles={labelStyles}>Card Number</Label>
          {changed ?
            <TextField
              onChange={handleChange}
              name="cardExpiry"
              disabled={true}
              styles={textFieldStyles}
              placeholder={`XXXX XXXX XXXX ${initialData?.cardNumber}`}
              errorMessage={touched?.cardExpiry ? errors?.cardExpiry : undefined}
            /> :
            <div className="custom-stripe-card-outer">
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "14px",
                      // color: "#424770",
                      backgroundColor: "white",
                      "::placeholder": {
                        color: "#aab7c4",
                      },

                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </div>}
        </StyledContent>


        {/* <StyledContent>
          <Label styles={labelStyles}>Card Type</Label>
          <Dropdown
            options={[
              { key: CardType.Visa, text: CardType.Visa },
              { key: CardType.Master, text: CardType.Master },
              { key: CardType.Discover, text: CardType.Discover },
            ]}
            selectedKey={values.cardType}
            onChange={onCardTypeChange}
            placeholder="Select options"
            styles={{
              dropdown: { width: 350 },
            }}
            errorMessage={touched?.cardType ? errors?.cardType : undefined}
            disabled={disabled}
          />
        </StyledContent> */}
        {/* <StyledContent>
          <Label styles={labelStyles}>Name on Card</Label>
          <TextField
            value={values.nameOnCard}
            onChange={handleChange}
            name="nameOnCard"
            styles={textFieldStyles}
            errorMessage={touched?.nameOnCard ? errors?.nameOnCard : undefined}
            disabled={disabled}
          />
        </StyledContent> */}
        {/* <StyledContent>
          <Label styles={labelStyles}>Card expiry</Label>
          <TextField
            value={values.cardExpiry}
            onChange={handleChange}
            name="cardExpiry"
            styles={textFieldStyles}
            errorMessage={touched?.cardExpiry ? errors?.cardExpiry : undefined}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Security Code</Label>
          <TextField
            value={String(values.securityCode)}
            onChange={handleChange}
            name="securityCode"
            styles={textFieldStyles}
            type="number"
            min={0}
            errorMessage={
              touched?.securityCode ? errors?.securityCode : undefined
            }
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Zip Code</Label>
          <TextField
            value={String(values.zipCode)}
            onChange={handleChange}
            name="zipCode"
            styles={textFieldStyles}
            type="number"
            min={0}
            errorMessage={
              touched?.zipCode ? errors?.zipCode : undefined
            }

          />

        </StyledContent> */}


        <StyledContent>
          <Label styles={labelStyles}>Make this payment Method Default</Label>
          <Checkbox
            checked={values.makeAsDefault}
            onChange={handleChangeDefaultMethod}
            name="makeAsDefault"
          />
        </StyledContent>
      </>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={changed ? handleDefaultPayment : generateStripeToken}
          text={"Add Payment Method"}
          disabled={mutation.isLoading}
        />
        {/* <PrimaryButton
          text={"Clear"}
          onClick={handleReset}
          styles={{ root: { float: "right", marginRight: "20px" } }}
        /> */}
      </ButtonContainer>
    </div>
  );
};

export default PaymentSettingForm;
