import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import {
  PrimaryButton,
  TextField,
  Label,
  Checkbox,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./SmeRegistrationForm.styles";
import {
  initialData,
  schema,
  SmeRegistrationType,
} from "./SmeRegistrationForm.utils";
import { getServerError, subjectAreas } from "../../utils/utils";

const SmeRegistrationForm = () => {
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("SME is successfully registered");
  };

  const { data } = useQuery("getSMEs", () => {
    return axios.get(
      "https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/"
    );
  });

  const mutation = useMutation(
    (data: SmeRegistrationType) => {
      return axios.post(
        "https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const validateForm = (val: SmeRegistrationType) => {
    try {
      validateYupSchema(val, schema, true, {
        smes: data?.data,
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  };

  const { setFieldValue, values, touched, errors, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialData,
      validate: validateForm,
      onSubmit: (values) => {
        mutation.mutate(values);
      },
    });

  const onChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue(
        "areaOfExpertise",
        item.selected
          ? [...values.areaOfExpertise, item.key]
          : values.areaOfExpertise.filter((key) => key !== item.key)
      );
    }
  };

  return (
    <div>
      <StyledContent>
        <Label styles={labelStyles}>First Name</Label>
        <TextField
          value={values.firstName}
          onChange={handleChange}
          name="firstName"
          styles={textFieldStyles}
          errorMessage={touched?.firstName ? errors?.firstName : undefined}
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Last Name</Label>
        <TextField
          value={values.lastName}
          onChange={handleChange}
          name="lastName"
          styles={textFieldStyles}
          errorMessage={touched?.lastName ? errors?.lastName : undefined}
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Contact Number</Label>
        <TextField
          value={values.contactNumber}
          onChange={handleChange}
          name="contactNumber"
          styles={textFieldStyles}
          errorMessage={
            touched?.contactNumber ? errors?.contactNumber : undefined
          }
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Email</Label>
        <TextField
          value={values.email}
          onChange={handleChange}
          name="email"
          styles={textFieldStyles}
          errorMessage={touched?.email ? errors?.email : undefined}
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Area of expertise</Label>
        <Dropdown
          options={subjectAreas}
          multiSelect={true}
          selectedKeys={values.areaOfExpertise}
          onChange={onChange}
          placeholder="Select options"
          styles={{
            dropdown: { width: 350 },
          }}
          // @ts-ignore
          errorMessage={
            touched?.areaOfExpertise ? errors?.areaOfExpertise : undefined
          }
        />
      </StyledContent>
      <StyledContent>
 <Label styles={{ root: { fontSize: "16px", fontWeight: "bold" } }}>
          Background and Proficiency
        </Label>
        <TextField  value={values.backgroundProficiency ? String(values.backgroundProficiency) : ""}
          onChange={handleChange}
          name="backgroundProficiency"
          styles={textFieldStyles}
          errorMessage={
            touched?.backgroundProficiency ? errors?.backgroundProficiency : undefined
          }
/>
     </StyledContent>
      <StyledContent style={{ alignItems: "baseline" }}>
        <Label styles={labelStyles}>I agree to</Label>
        <Checkbox
          checked={values.agreeToTerms}
          onChange={handleChange}
          name="agreeToTerms"
        />
        <Label styles={{ root: { fontSize: "16px", fontWeight: "bold" } }}>
          Terms
        </Label>
        <TextField
          readOnly
          value={
            "I understand that this CHP platform is not responsible for any consequences that could raise out this consulting proceeds. I agree to pay 20% of my consulting fee to USI towards the service charge. I agree that I won't disclose my contact details to any customers that met here."
          }
          multiline={true}
          styles={{ root: { width: "270px", marginLeft: "10px" } }}
          errorMessage={
            touched?.agreeToTerms ? errors?.agreeToTerms : undefined
          }
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Consultation Fee per hour: (In $)</Label>
        <TextField
          value={values.consultationFee ? String(values.consultationFee) : ""}
          onChange={handleChange}
          name="consultationFee"
          styles={textFieldStyles}
          errorMessage={
            touched?.consultationFee ? errors?.consultationFee : undefined
          }
          type={"number"}
        />
      </StyledContent>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={handleSubmit}
          text={"Register"}
          disabled={mutation.isLoading}
        />
      </ButtonContainer>
    </div>
  );
};

export default SmeRegistrationForm;
