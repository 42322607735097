import { Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserProfile from "../UserProfile/UserProfile";
import PersonalDetails from "./PersonalDetails/PersonalDetails";

const CustomerProfilePage = () => {
  const location = useLocation();
  const [route, setRoute] = useState("Personal");
  useEffect(() => {
    if (location?.state) {
      setRoute("Payment");
    }
  }, []);

  return (
    <div className="customerProfile">
      <Pivot
        linkFormat="tabs"
        onLinkClick={(e: any) => setRoute(e?.props?.itemKey)}
        selectedKey={route}
      >
        <PivotItem headerText="Personal Setting" itemKey="Personal">
          <PersonalDetails />
        </PivotItem>
        <PivotItem headerText="Payment Settings" itemKey="Payment">
          <UserProfile />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default CustomerProfilePage;
