import React, { useEffect, useState, useCallback } from "react";
import { DetailsList, Dropdown, Label } from "@fluentui/react";
import axios from "axios";
import { useQuery } from "react-query";
import SpinningLoader from "../../Loaders/SpinningLoader";
import { getNumberFromTime } from "./Admin.utils";
import "./ExpenseReports.css";

const dropdownStyles = {
  callout: { maxHeight: 200 },
  dropdown: { width: 200 },
};

const ApiData = async () => {
  const { data } = await axios.get(
    "https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod"
  );

  return data;
};

const getConsultingFee = async () => {
  const { data } = await axios.get(
    "https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod"
  );
  return data;
};

const getSMEEmails = (combinedData) => {
  if (Array.isArray(combinedData)) {
    const uniqueEmails = [
      ...new Set(combinedData.map((item) => item.smeEmail)),
    ];
    const options = uniqueEmails?.map((email) => {
      return {
        key: email,
        text: email,
      };
    });
    return [{ key: "All", text: "" }, ...(options || [])];
  }
  return [];
};

const ExpenseReport = () => {
  const [filterEmail, setFilterEmail] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data: appointmentData, isLoading } = useQuery("ApiData", ApiData, {
    staleTime: Infinity,
    onSuccess: () => {
      setLoading(false);
    },
  });

  const { data: smeData } = useQuery("smeDetails", getConsultingFee, {
    staleTime: Infinity,
    onSuccess: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (Array.isArray(appointmentData) && Array.isArray(smeData)) {
      let combinedData = [];

      appointmentData?.forEach((appointment, i) => {
        if (appointment?.appointmentStatus?.toLowerCase() === "finished") {
          const newSMEData = smeData.find(
            (sme) => appointment.smeEmail === sme.email
          );
          if (newSMEData) {
            const {
              customerFirstName,
              appointmentTime,
              smeFirstName,
              appointmentStatus,
              smeEmail,
              customerEmail,
              subjectArea,
              appointmentDuration,
            } = appointment;
            const data = smeData?.find((user) => user?.email === smeEmail);

            combinedData.push({
              customerFirstName: customerFirstName || "-",
              appointmentTime: appointmentTime || "-",
              smeFirstName: smeFirstName || "-",
              appointmentStatus: appointmentStatus || "-",
              consultationFee: data?.consultationFee || "-",
              smeEmail: smeEmail || "-",
              customerEmail: customerEmail || "-",
              subjectArea: subjectArea || "-",
              appointmentDuration: appointmentDuration || "-",
            });
          }
        }
      });
      setExpenseData(combinedData);
    }
  }, [appointmentData, smeData]);

  let columns = [
    {
      key: "column1",
      name: "Customer Name",
      fieldName: "customerFirstName",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column2",
      name: "Customer Email",
      fieldName: "customerEmail",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column3",
      name: "SME Name",
      fieldName: "smeFirstName",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column4",
      name: "SME Email",
      fieldName: "smeEmail",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column5",
      name: "Consulting Fees",
      fieldName: "consultationFee",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column6",
      name: "Subject Area",
      fieldName: "subjectArea",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column7",
      name: "Appointment Duration",
      fieldName: "appointmentDuration",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column8",
      name: "Appointment Time",
      fieldName: "appointmentTime",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column9",
      name: "Total Charge ",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
      onRender: (item) => (
        <p>
          {parseInt(item.consultationFee) *
            getNumberFromTime(item.appointmentDuration) || "0"}
        </p>
      ),
    },
  ];

  const handleEmailFilter = useCallback(
    (email) => {
      setFilterEmail(email);
      const filterReports = expenseData?.filter(
        (item) => item?.smeEmail === email
      );
      setFilteredData(filterReports);
    },
    [expenseData]
  );

  if (isLoading || loading) {
    return <SpinningLoader isLoading={isLoading || loading} />;
  }
  return (
    <div style={{ padding: "30px" }}>
      <center style={{ fontSize: "2rem", color: "black" }}>
        Expense Reports
      </center>

      <div className="filterDropdown">
        <Label className="task-header">SME Email:&nbsp;</Label>
        <Dropdown
          onChange={(e, item) => handleEmailFilter(item.text)}
          options={getSMEEmails(expenseData)}
          styles={dropdownStyles}
          name="smeEmail"
        />
      </div>

      {!expenseData?.length ? (
        <center className="no_reports">No reports found for this user </center>
      ) : (
        <DetailsList
          selection={false}
          items={filterEmail ? filteredData : expenseData}
          columns={columns}
          disableSelectionZone
        />
      )}
    </div>
  );
};

export default ExpenseReport;
