
/* eslint-disable react-hooks/rules-of-hooks */
import { DefaultButton, Label, Modal } from "@fluentui/react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import {
  SmeName,
  StyledContent,
  AppTimeContainer,
  TextFieldsContainer,
  TextArea,
  BtnContainer,
  Container,
  AppSmeName,
  DetailPanel,
} from "./AppointmaintSessionStyles";
import { useEffect, useState, useContext, useRef } from "react";
import CurrentUserContext from "../../CurrentUserContext";
import { getServerError } from "../../utils/utils";

function AppoinitmentDetailsForm() {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
  const navigate = useNavigate();
  const { key } = useParams();
  const [imageSrc, setImageSrc] = useState("");
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [isHistoryView, setIsHistoryView] = useState(false);
  const context = useContext(CurrentUserContext);
  const messagesEndRef = useRef(null);

  const socketInstance = useRef(
    new WebSocket(`wss://3rzvj9xn41.execute-api.us-east-1.amazonaws.com/dev/`)
  );

  const { data, isLoading } = useQuery("getBankAccounts", () => {
    return axios.get(
      `https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/${key}`,
      {
        headers: {},
      }
    );
  });

  const handleError = (error) => {
    setLoadingMessages(false);
    alert(getServerError(error));
  };

  const handleSuccess = (res) => {
    setLoadingMessages(false);
    let messagesList = res?.data?.Item?.messages;
    if (messagesList && !!messagesList?.length) {
      setMessages((prevMessages) => [...prevMessages, ...messagesList]);
    }
  };

  const mutation = useMutation(
    (data) => {
      return axios.post(
        "https://axunrspzed.execute-api.us-east-1.amazonaws.com/Prod/getmessage",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  useEffect(() => {
    if (isHistoryView) {
      setLoadingMessages(true);
      mutation.mutate({
        sessionChannelId: key,
      });
    }
  }, [isHistoryView]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setImageSrc("");
  };

  const viewImageModal = (src) => {
    setViewModal(true);
    setImageSrc(src);
  };

  const closeModal = () => {
    setViewModal(false);
    setImageSrc("");
  };
  const sessionDurations = {
    "30 min": 1.8e6,
    "1 hr": 13.6e6,
    "2 hr": 7.2e6,
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  useEffect(() => {
    if (!isLoading && data) {
      socketInstance.current.onopen = () => {
        setIsWebSocketOpen(true);
      };
      socketInstance.current.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        if (typeof eventData?.message == "boolean") {
          socketInstance.current?.close();
          setIsWebSocketOpen(false);
          socketInstance.current = null;
          navigate("/appointments");
          localStorage.removeItem("sessionChannelId");
          setTimeout(() => {
            alert("Chat has been Ended");
          }, 5000);
        } else {
          if (eventData.userType) {
            setMessages((prevMessages) => [...prevMessages, eventData]);
          }
        }
      };
      socketInstance.current.onclose = (event) => {
        setIsWebSocketOpen(false);
      };
      socketInstance.current.onerror = (error) => {
        setIsWebSocketOpen(false);
        socketInstance.current = null;
      };
    }
  }, [data, isLoading, navigate]);

  const getSessionChannelId = localStorage.getItem("sessionChannelId");

  const getAppointmentEndTime = (timeLimit) => {
    const appTime = new Date(appointmentTime).getTime();
    const appEndTime = new Date(appTime + timeLimit).getTime();
    const currentTime = new Date().getTime();
    const timeOut = appEndTime - currentTime;

    if (isWebSocketOpen) {
      const data = {
        appointmentId: appointmentId,
        subjectArea: subjectArea,
        smeEmail: smeEmail,
        smeFirstName: smeFirstName,
        smeLastName: smeLastName,
        appointmentTime: appointmentTime,
        appointmentDuration: appointmentDuration,
        customerFirstName: customerFirstName,
        customerEmail: customerEmail,
        appointmentStatus: "Finished",
        agreeToTerms: true,
      };
      mutate(data);
      if (!!socketInstance.current) {
        const payload = {
          action: "sendmessage",
          message: true,
          isChatEnd: true,
          userType: context.user.role === "customer" ? "USER" : "SME",
          sessionChannelId: getSessionChannelId,
          mType: file ? "File" : "Text",
        };
        socketInstance.current.send(JSON.stringify(payload));
      }
      navigate(-1);
      socketInstance.current = null;
      localStorage.removeItem("sessionChannelId");
    }
  };

  const handleSendMessage = async () => {
    if (message || file) {
      const payload = {
        action: "sendmessage",
        message: message || file,
        userType: context.user.role === "customer" ? "USER" : "SME",
        sessionChannelId: getSessionChannelId,
        mType: file ? "File" : "Text",
      };
      if (isWebSocketOpen == false) {
        window.location.reload()
      }
      socketInstance.current.send(JSON.stringify(payload));
      setMessages((prevMessages) => [...prevMessages, payload]);
      setMessage("");
      setFile(null);
    } else {
      alert("Please enter a message or select a file to send");
    }
  };

  const handleFileUpload = (imageData) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        const img = new Image();
        img.src = "data:image/png;base64," + base64String;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        img.onload = () => {
          const maxWidth = 500;
          let width = img.width;
          let height = img.height;
          if (width > maxWidth || height > maxWidth) {
            if (width > height) {
              height *= maxWidth / width;
              width = maxWidth;
            } else {
              width *= maxWidth / height;
              height = maxWidth;
            }
          }
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.7);

          const payload = {
            action: "sendmessage",
            message: compressedDataUrl,
            userType: context.user.role === "customer" ? "USER" : "SME",
            sessionChannelId: getSessionChannelId,
            mType: "File",
          };
          socketInstance.current.send(JSON.stringify(payload));
          setMessages((prevMessages) => [...prevMessages, payload]);
        };
        setSelectedFile("");
        document.getElementById("fileInput").value = "";
      };
    } else if (imageData) {
      const img = new Image();
      img.src = imageData;
      setImageSrc(img.src);
    } else {
      alert("Please select a file to send");
    }
  };

  const {
    customerFirstName,
    appointmentTime,
    appointmentId,
    smeFirstName,
    smeLastName,
    appointmentStatus,
    smeEmail,
    appointmentDuration,
    customerEmail,
    subjectArea,
  } = data?.data || {};

  const appTime = new Date(appointmentTime).getTime();
  const appEndTime = new Date(
    appTime + sessionDurations[appointmentDuration]
  ).getTime();
  const currentTime = new Date();
  const sessionStartTime = new Date(appointmentTime);
  const sessionEndTime = new Date(appEndTime);

  useEffect(() => {
    const today = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate()
    );
    const appointment_time = new Date(appointmentTime);
    if (appointment_time < today) {
      setIsHistoryView(true);
    }
  }, [data?.data]);

  const isSessionActive = () => {
    return currentTime >= sessionStartTime && currentTime <= sessionEndTime;
  };

  const clearSession = () => {
    socketInstance.current?.close();
    setIsWebSocketOpen(false);
    socketInstance.current = null;
    navigate("/appointments");
    localStorage.removeItem("sessionChannelId");
    setTimeout(() => {
      alert("Chat has been Ended");
    }, 5000);
  };

  useEffect(() => {
    const today = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate()
    );
    const appointment_time = new Date(appointmentTime);
    if (
      currentTime >= sessionStartTime &&
      currentTime >= sessionEndTime &&
      appointment_time > today
    ) {
      clearSession();
    }
  }, [messages, file, isHistoryView]);
  const endApointment = async (data) => {
    const { data: response } = await axios.post(
      `https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/`,
      data,
      { headers: { "Content-Type": "application/json" } }
    );

    return response;
  };

  const handleChatEnd = () => {
    const confirmEnd = window.confirm("Are you sure you want to end ? ");
    const data = {
      appointmentId: appointmentId,
      subjectArea: subjectArea,
      smeEmail: smeEmail,
      smeFirstName: smeFirstName,
      smeLastName: smeLastName,
      appointmentTime: appointmentTime,
      appointmentDuration: appointmentDuration,
      customerFirstName: customerFirstName,
      customerEmail: customerEmail,
      appointmentStatus: "Finished",
      agreeToTerms: true,
    };
    mutate(data);
    if (!!socketInstance.current) {
      const payload = {
        action: "sendmessage",
        message: true,
        isChatEnd: true,
        userType: context.user.role === "customer" ? "USER" : "SME",
        sessionChannelId: getSessionChannelId,
        mType: file ? "File" : "Text",
      };
      socketInstance.current.send(JSON.stringify(payload));
    }
    if (confirmEnd) {
      navigate(-1);
      socketInstance.current = null;
    }
    localStorage.removeItem("sessionChannelId");
  };
  const { mutate } = useMutation(endApointment);
  if (isLoading || loadingMessages) {
    return <div>Loading Data</div>;
  }

  const handleBackClick = () => {
    navigate("/appointments");
    localStorage.removeItem("sessionChannelId");
  }

  return (
    <Container>
      <StyledContent className="header">
        <AppTimeContainer>
          <span style={{ margin: 4, fontWeight: 600 }}>
            Appointment Id :{appointmentId}
          </span>
          <span style={{ margin: 4, fontWeight: 600 }}>
            Appointment Time :{appointmentTime}
          </span>
        </AppTimeContainer>
        <SmeName>
          <AppSmeName>
            Appointment With&nbsp;
            {context.user.role === "customer"
              ? smeFirstName?.slice(0, 1).toUpperCase() + smeFirstName?.slice(1)
              : customerFirstName?.slice(0, 1).toUpperCase() +
                customerFirstName?.slice(1)}
          </AppSmeName>
        </SmeName>
      </StyledContent>

      <TextFieldsContainer className="text-field-container">
        <DetailPanel>
          {isHistoryView && !loadingMessages && messages && !messages.length && (
            <div>
               <p style={{textAlign: 'center'}}>No Chat History Available</p>
            </div>
          )}
          {messages?.map((msg, index) => (
            <div ref={messagesEndRef} key={index}>
              <div>
                <span>
                  {msg?.userType?.toLowerCase() ===
                  context?.user?.role?.toLowerCase()
                    ? "You"
                    : msg.userType === "USER"
                    ? customerFirstName
                    : smeFirstName}
                </span>
                <b>{msg.mType === "Text" ? ":" : ""}</b>
                <span>{msg.mType === "File" ? "" : msg.message}</span>

                {msg.mType === "File" && (
                  <img
                    onClick={() => viewImageModal(msg.message)}
                    style={{
                      transition: "border-color 0.10s ease-in-out",
                      height: "70px",
                      width: "90px",
                      border: "1px solid red",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    src={msg.message}
                    alt="Received "
                    onMouseOver={(e) => (e.target.style.borderColor = "blue")}
                    onMouseOut={(e) => (e.target.style.borderColor = "red")}
                  />
                )}
              </div>
            </div>
          ))}
        </DetailPanel>
        {!isHistoryView ? (
          <>
            <div style={{ margin: "auto", display: "flex" }}>
              <TextArea
                placeholder="Enter Message"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
              />
		
              <BtnContainer style={{ margin: 3, marginLeft: 40 }}>
                <DefaultButton
		   text={"Video Call"}
		   onClick={() => {console.log(`https://videocall.chp-usi.com?appointmentId=${appointmentId}`); window.open(`https://d2jbyagb6uwxif.cloudfront.net?appointmentId=${appointmentId}`, "_blank", "noreferrer");} }
		/>
		<DefaultButton
                  text={"Send"}
                  onClick={() => handleSendMessage()}
                />
                <Label>Upload Image</Label>
                <input type="file" id="fileInput" onChange={handleFileSelect} />
                <DefaultButton
                  text="Upload"
                  onClick={() => handleFileUpload()}
                />
              </BtnContainer>
            </div>
            <DefaultButton
              style={{ margin: 3 }}
              onClick={() => handleChatEnd()}
              text={"End"}
            />
          </>
        ) : (
          <DefaultButton
            style={{ margin: 3 }}
            onClick={() => handleBackClick()}
            text={"Back"}
          />
        )}
      </TextFieldsContainer>
      {viewModal && (
        <Modal isOpen={true} onDismiss={closeModal} isBlocking={false}>
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <img style={{ height: "100%", width: "100%" }} src={imageSrc} />
          </div>
        </Modal>
      )}
    </Container>
  );
}

export default AppoinitmentDetailsForm;
