import * as React from "react";
import styled from "styled-components";
import appImg from "../../assets/png/appAuthImg.png";

const StyledContainer = styled.div`
  color: #b2b2b2;
  font-weight: 800;
  font-size: 18px;
  position: absolute;
  top: 13%;
  margin-top: -3%;
  left: 2%;
  padding: 10px;
  transition: 0.3s;
  overflow-y: auto;
  max-height: 67%;
`;

const AppoinitmentDetailsLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <div style={{ height: "100vh" }}>
      <img src={appImg} width={"100%"} height={"100%"} alt={"background"} />
      <StyledContainer>{props.children}</StyledContainer>
    </div>
  );
};

export default AppoinitmentDetailsLayout;
