import { AxiosError } from "axios";

export const getServerError = (error: AxiosError) => {
  // @ts-ignore
  const err = error.response?.data?.err;
  if (err) {
    return err;
  }
  return "Sorry something went wrong. Please try again later";
};

export const USER_SESSION_KEY = "user_session";
export const SME_SESSION_KEY = "sme_session";

export const subjectAreas = [
  { key: "SoftwareConsulting", text: "Software Consulting" },
  { key: "MedicalConsulting", text: "Medical Consulting" },
  { key: "FinancialConsulting", text: "Financial Consulting" },
  { key: "PlumbingConsulting", text: "Plumbing Consulting" },
  { key: "CarpenterConsulting", text: "Carpenter Consulting" },
  { key: "CookingServiceConsulting", text: "CookingService Consulting" },
  { key: "RealtorAndRefinancingConsulting", text: "Realtor and Refinance Consulting"},
  { key: "ElectricalConsulting", text: "Electrical Consulting"},
  { key: "DrywallWorkConsulting", text: "Drywall Consulting"},
  { key: "TutoringConsulting", text: "Education/Tutoring Service" },
  { key: "TailoringOrSweing", text: "Tailoring Service" },
  { key: "CraftAndInteriorDecoration", text: "Craft And Interior Decoration" },
  { key: "MechanicConsultation", text: "Mechanic Consultation" },
  { key: "LegalConsultation", text: "Legal Consultation" },
  { key: "ExteriorConstructionConsulting", text: "Exterior Construction Consulting" },
  { key: "BeautyConsulting", text: "Beauty Consultant Services" },
  { key: "OtherServices", text: "Other Servies Consulting"}

];
