import * as Yup from "yup";

export interface CustomerFeedbackType {
  reviewId: number;
  issueArea: string;
  smeEmail: string;
  feedback: string;
  rating: string;
}

export const getInitialData = (email?: string): CustomerFeedbackType => ({
  reviewId: Date.now(),
  smeEmail: email ?? "",
  issueArea: "",
  rating: "",
  feedback: "",
});

export const schema = Yup.object().shape({
  rating: Yup.string().label("Rating").required(),
  issueArea: Yup.string().label("Issue Area").required(),
});

const rating = {
  "1 star": 1,
  "2 stars": 2,
  "3 stars": 3,
  "4 stars": 4,
  "5 stars": 5,
};

const ratingValue = {
  1: "1 star",
  2: "2 stars",
  3: "3 stars",
  4: "4 stars",
  5: "5 stars",
};

export const getRating = (data: string): number => {
  // @ts-ignore
  return rating[data] ?? 0;
};

export const getRatingValue = (data: number): string => {
  // @ts-ignore
  return ratingValue[data] ?? "";
};
