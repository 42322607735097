import {
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  Label,
  PrimaryButton,
  Rating,
  TextField,
} from "@fluentui/react";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation } from "react-query";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
} from "./CustomerFeedback.styles";
import {
  CustomerFeedbackType,
  getInitialData,
  getRating,
  getRatingValue,
  schema,
} from "./CustomerFeedback.utils";
import axios, { AxiosError } from "axios";
import { getServerError, subjectAreas } from "../../utils/utils";

const CustomerFeedback = () => {
  const [emailSearched, setEmailSearched] = React.useState<string>("");

  const handleEmailSearchFieldChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setEmailSearched(newValue ?? "");
  };

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Feedback is submitted");
  };

  const handleGetSmeListSuccess = (data: {
    data: {
      email: string;
      firstName: string;
      lastName: string;
      consultationFee: string;
    }[];
  }) => {
    setFieldValue("smeEmail", data?.data?.[0]?.email);
  };

  const getSmeList = useMutation(
    () => {
      return axios.get(
        `https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/sme/${emailSearched}`,
        {
          headers: {},
        }
      );
    },
    { onSuccess: handleGetSmeListSuccess }
  );

  const handleSmeSearch = () => {
    if (!emailSearched) {
      alert("Please enter email");
      return;
    }
    getSmeList.mutate();
  };

  const mutation = useMutation(
    (data: CustomerFeedbackType) => {
      return axios.post(
        "https://59f4aemlt5.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const handleSmeSelect = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setFieldValue("smeEmail", option?.key);
  };

  const { setFieldValue, values, handleSubmit, errors, touched, handleChange } =
    useFormik({
      initialValues: getInitialData(),
      validationSchema: schema,
      onSubmit: (values) => {
        mutation.mutate(values);
      },
    });

  const onToggleChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("issueArea", item.key);
    }
  };

  const handleRatingChange = (
    _event: React.FormEvent<HTMLElement>,
    rating?: number
  ) => {
    if (rating) {
      setFieldValue("rating", getRatingValue(rating));
    }
  };

  return (
    <div>
      <StyledContent>
        <Label styles={labelStyles}>Issue Area</Label>
        <Dropdown
          options={subjectAreas}
          selectedKey={values.issueArea}
          onChange={onToggleChange}
          placeholder="Select options"
          styles={{
            dropdown: { width: 270, marginBottom: 10, marginLeft: 10 },
          }}
          errorMessage={touched?.issueArea ? errors?.issueArea : undefined}
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>SME Email</Label>
        <TextField
          value={emailSearched}
          styles={{ root: { width: "270px", marginLeft: "10px" } }}
          onChange={handleEmailSearchFieldChange}
        />
        <PrimaryButton
          onClick={handleSmeSearch}
          text={"Search"}
          disabled={getSmeList.isLoading}
          styles={{ root: { marginLeft: "10px" } }}
        />
      </StyledContent>
      {getSmeList.isLoading ? (
        <div>{"Loading..."}</div>
      ) : !getSmeList.data?.data.length ? (
        <div>No SME Found</div>
      ) : (
        <div>
          <div>
            <StyledContent>
              <Label styles={labelStyles}>Available SMEs</Label>
              <div
                style={{
                  display: "flex",
                  height: "fit-content",
                  backgroundColor: "white",
                  padding: "10px",
                  gap: "10px",
                }}
              >
                <ChoiceGroup
                  options={getSmeList?.data?.data.map(
                    (item: {
                      firstName: string;
                      lastName: string;
                      email: string;
                    }) => ({
                      key: String(item.email),
                      text: `${item.firstName} ${item.lastName}`,
                    })
                  )}
                  onChange={handleSmeSelect}
                  selectedKey={values.smeEmail}
                />
              </div>
            </StyledContent>
            <StyledContent>
              <Label styles={labelStyles}>Feedback / Review</Label>
              <TextField
                value={values.feedback}
                styles={{ root: { width: "270px", marginLeft: "10px" } }}
                onChange={handleChange}
                name="feedback"
                multiline
              />
            </StyledContent>
            <StyledContent>
              <Label styles={labelStyles}>Rating</Label>
              <Rating
                max={5}
                ariaLabelFormat="{0} of {1} stars"
                onChange={handleRatingChange}
                rating={getRating(values.rating)}
                allowZeroStars
                styles={{ root: { marginLeft: 10 } }}
              />
              {errors.rating && touched.rating ? (
                <div style={{ fontSize: "10px", color: "red" }}>
                  {errors.rating}
                </div>
              ) : null}
            </StyledContent>
            <ButtonContainer>
              <PrimaryButton
                // @ts-ignore
                onClick={handleSubmit}
                text={"Submit"}
                disabled={mutation.isLoading}
              />
            </ButtonContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerFeedback;
