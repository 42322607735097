import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import SmeProfile from "../SmeProfile/SmeProfile";
import SmePersonalDetails from "./SmePersonalDetails/SmePersonalDetails";

const SmeProfilePage = () => {
  return (
    <div>
      <Pivot linkFormat="tabs" defaultSelectedKey="Payment">
        <PivotItem headerText="Personal Setting" itemKey="Personal">
          <SmePersonalDetails />
        </PivotItem>
        <PivotItem headerText="Profile Setting" itemKey="Payment">
          <SmeProfile />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default SmeProfilePage;
