import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AppoinitmentDetailsForm from "../AppointmentDetailsForm/AppoinitmentDetailsForm";
import AppoinitmentDetailsLayout from "../AppointmentDetailsForm/AppointmentDetailsStyles";
import CustomerAppointment from "../CustomerAppointment/CustomerAppointment";
import CustomerFeedback from "../CustomerFeedback/CustomerFeedback";
import CustomerProfilePage from "../CustomerProfilePage/CustomerProfilePage";
import AuthLayout from "../Layout/AuthLayout";
import CustomerAppointmentLayout from "../Layout/CustomerAppointmentLayout";
import UserFeedbackLayout from "../Layout/UserFeedbackLayout";
import SignOut from "../SignOut/SignOut";


const UserAuthRoute = () => {
  return (
    <Routes  >
      <Route
        path="/"
        element={
          <AuthLayout>
            <CustomerProfilePage />
          </AuthLayout>
        }
      />
      < Route
        path="/profile"
        element={
          < AuthLayout >
            <CustomerProfilePage />
          </AuthLayout >
        }
      />
      
      < Route
        path="appointments"
        element={
          < CustomerAppointmentLayout >
            <CustomerAppointment />
          </CustomerAppointmentLayout >
        }
      />
      < Route
        path="feedback"
        element={
          < UserFeedbackLayout >
            <CustomerFeedback />
          </UserFeedbackLayout >
        }
      />
      < Route
        path="sign-out"
        element={
          < AuthLayout >
            <SignOut />
          </AuthLayout >
        }
      />
      < Route
        path="/appointmentdetailspage/:key"
        element={
          < AppoinitmentDetailsLayout >
            <AppoinitmentDetailsForm />
          </AppoinitmentDetailsLayout >
        }
      />

      < Route path="help" element={< AuthLayout > This is help page</AuthLayout >} />
      < Route element={< AuthLayout > Page Not found</AuthLayout >} path="*" />
    </Routes >
  );
};

export default UserAuthRoute;

