import { Dropdown, Label, TextField } from "@fluentui/react";
import * as React from "react";
import { subjectAreas } from "../../../utils/utils";
import {
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./UserDetail.styles";

interface UserDetailProps {
  values: {
    areaOfExpertise: string[];
    lastName: string;
    agreeToTerms: boolean;
    contactNumber: string;
    consultationFee: number;
    email: string;
    firstName: string;
  };
}

const UserDetail = ({ values }: UserDetailProps) => {
  return (
    <div style={{ position: "absolute" }}>
      <StyledContent>
        <Label styles={labelStyles}>First Name</Label>
        <TextField
          value={values.firstName}
          name="firstName"
          styles={textFieldStyles}
          readOnly
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Last Name</Label>
        <TextField
          value={values.lastName}
          name="lastName"
          styles={textFieldStyles}
          readOnly
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Contact Number</Label>
        <TextField
          value={values.contactNumber}
          name="contactNumber"
          styles={textFieldStyles}
          readOnly
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Email</Label>
        <TextField value={values.email} name="email" styles={textFieldStyles} />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Area of expertise</Label>
        <Dropdown
          options={subjectAreas}
          multiSelect={true}
          selectedKeys={values.areaOfExpertise}
          placeholder="Select options"
          styles={{
            dropdown: { width: 250 },
          }}
          disabled
        />
      </StyledContent>

      <StyledContent>
        <Label styles={labelStyles}>Consultation Fee per hour: (In $)</Label>
        <TextField
          value={values.consultationFee ? String(values.consultationFee) : ""}
          name="consultationFee"
          styles={textFieldStyles}
          type={"number"}
          readOnly
        />
      </StyledContent>
    </div>
  );
};

export default UserDetail;
