import * as Yup from "yup";

export enum CardType {
  Visa = "Visa",
  Master = "Master",
  Amex = "Amex",
}

export interface PaymentSettingType {
  makeAsDefaultPayment: boolean;
  bankRoutingNumber: string;
  paymentMethodId: number;
  accountNumber: string;
  accountName: string;
  smeEmail: string;
  bankName: string;
}

export const getInitialData = (email?: string): PaymentSettingType => ({
  makeAsDefaultPayment: false,
  bankRoutingNumber: "",
  paymentMethodId: 0,
  accountNumber: "",
  accountName: "",
  smeEmail: email ?? "",
  bankName: "",
});

export const schema = Yup.object().shape({
  accountName: Yup.string().label("Account Name").trim().required().max(50),
  bankName: Yup.string().label("Bank Name").required(),
  bankRoutingNumber: Yup.string()
    .label("Bank routing Number")
    .trim()
    .required(),
  accountNumber: Yup.string()
  .matches(/^\d{8,17}$/, 'Invalid account number')
  .required('Account number is required'),
  paymentMethodId: Yup.number().required(),
  makeAsDefaultPayment: Yup.bool().required(),
});