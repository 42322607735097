import * as Yup from "yup";
import {
  SmeAppointmentType,
  SmeBookedStatusType,
} from "../../SmeAppointment/SmeAppointmentForm/SmeAppointmentForm.utils";
import format from "date-fns/format";

export interface CustomerAppointmentType {
  appointmentId: number;
  subjectArea: string;
  smeEmail: string;
  smeFirstName: string;
  smeLastName: string;
  appointmentTime: string;
  appointmentStatus: string;
  agreeToTerms: boolean;
  appointmentDuration: string;
  customerFirstName: string;
  customerEmail: string;
  customerLastName: string;
}

export const getInitialData = (
  email?: string,
  firstName?: string,
  lastName?: string
): CustomerAppointmentType => ({
  appointmentId: 0,
  smeEmail: "",
  smeFirstName: "",
  smeLastName: "",
  appointmentTime: "",
  appointmentStatus: "Booked",
  agreeToTerms: false,
  subjectArea: "",
  customerEmail: email ?? "",
  appointmentDuration: "30 min",
  customerFirstName: firstName ?? "",
  customerLastName: lastName ?? "",
});

export const getTimeInterval = (input: string) => {
  return input === "30 min" ? 30 : input === "1 hr" ? 60 : 120;
};

export const schema = Yup.object().shape({
  appointmentTime: Yup.string().label("Appointment Time").required(),
  agreeToTerms: Yup.bool()
    .required()
    .oneOf([true], "The terms and conditions must be accepted."),
});

export const mockData = [
  {
    appointmentTime: "05-16-2022 06:00 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652618662701,
    availabilityStatus: "available",
  },
  {
    appointmentTime: "05-15-2022 03:00 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652628491517,
    availabilityStatus: "unavailable",
  },
  {
    appointmentTime: "05-15-2022 12:30 PM",
    smeFirstName: "dd",
    smeLastName: "dd",
    smeEmail: "dd@f.com",
    appointmentDuration: "30 min",
    forecastId: 1652628491517,
    availabilityStatus: "unavailable",
  },
];

export const getExcludeTime = (
  data: SmeAppointmentType[],
  booked_data: SmeBookedStatusType[]
) => {
  const unAvailableData = data?.reduce((acc: any, item: SmeAppointmentType) => {
    let timeInterval = getTimeInterval(item.appointmentDuration);

    if (item.availabilityStatus === "unavailable") {
      let dateToChange = new Date(item?.appointmentTime);
      if (timeInterval === 120) {
        let arr = [30, 30, 30];
        acc.push(item?.appointmentTime);
        arr.forEach((val) => {
          let d = new Date(
            dateToChange.setMinutes(dateToChange.getMinutes() + val)
          );
          acc.push(format(d, "MM-dd-yyyy hh:mm aa"));
        });
      } else if (timeInterval === 60) {
        acc.push(item?.appointmentTime);
        let d1 = new Date(
          dateToChange.setMinutes(dateToChange.getMinutes() + 30)
        );
        acc.push(format(d1, "MM-dd-yyyy hh:mm aa"));
      } else if (timeInterval === 30) {
        acc.push(item?.appointmentTime);
      }
    }
    return acc;
  }, []);

  const bookedData = booked_data?.reduce(
    (acc: any, item: SmeBookedStatusType) => {
      let timeInterval = getTimeInterval(item.appointmentDuration);
      if (item?.appointmentStatus === "Booked") {
        let dateToChange = new Date(item?.appointmentTime);

        if (timeInterval === 120) {
          let arr = [30, 30, 30];
          acc.push(item?.appointmentTime);
          arr.forEach((val) => {
            let d = new Date(
              dateToChange.setMinutes(dateToChange.getMinutes() + val)
            );
            acc.push(format(d, "MM-dd-yyyy hh:mm aa"));
          });
        } else if (timeInterval === 60) {
          acc.push(item?.appointmentTime);
          let d1 = new Date(
            dateToChange.setMinutes(dateToChange.getMinutes() + 30)
          );
          acc.push(format(d1, "MM-dd-yyyy hh:mm aa"));
        } else if (timeInterval === 30) {
          acc.push(item?.appointmentTime);
        }
      }
      return acc;
    },
    []
  );

  const filterTimes = [...unAvailableData, ...bookedData];
  return filterTimes;
};

export const getExistingForecast = (
  data: CustomerAppointmentType[],
  date: string
) => {
  return data.find((item) => item.appointmentTime === date);
};
