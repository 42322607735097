import * as Yup from "yup";

export interface SmeRegistrationType {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  areaOfExpertise: string[];
  agreeToTerms: true;
  consultationFee?: number;
  backgroundProficiency: string;
  profilePictureLocation: string;
}

export const getInitialData = (data?: any): SmeRegistrationType => ({
  email: data?.email ?? "",
  firstName: data?.firstName ?? "",
  lastName: data?.lastName ?? "",
  contactNumber: data?.contactNumber ?? "",
  areaOfExpertise: data?.areaOfExpertise ?? [],
  agreeToTerms: true,
  consultationFee: data?.consultationFee ?? undefined,
  backgroundProficiency: data?.backgroundProficiency ?? undefined,
  profilePictureLocation: "",
});

export const schema = Yup.object().shape({
  email: Yup.string().label("Email").trim().required().email(),
  firstName: Yup.string().label("First Name").trim().required().max(50),
  lastName: Yup.string().label("Last Name").trim().required().max(50),
  contactNumber: Yup.string()
    .label("Contact Number")
    .required()
    .matches(/^\d+$/, "Contact Number should be a number"),
  agreeToTerms: Yup.bool()
    .required()
    .oneOf([true], "The terms and conditions must be accepted."),
  areaOfExpertise: Yup.array()
    .label("Issue Areas")
    .of(Yup.string())
    .min(1)
    .required(),
  consultationFee: Yup.number().label("Consultation Fee").required().positive(),
});
