import {
  CheckboxVisibility,
  DetailsList,
  SelectionMode,
  Spinner,
  Selection,
  Label,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import CurrentUserContext from "../../CurrentUserContext";
import { getServerError } from "../../utils/utils";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
} from "./SmeFeeback.styles";
import {
  columns,
  getInitialData,
  schema,
  SmeFeedbackType,
} from "./SmeFeedback.utils";

const SmeFeedback = () => {
  const context = React.useContext(CurrentUserContext);
  const [selectedItem, setSelectedItem] = React.useState<Object | undefined>(
    undefined
  );

  const selection = React.useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedItem(selection?.getSelection()?.[0]);
        },
      }),
    []
  );

  const handleQuerySuccess = (data: any) => {
    if (data?.data?.length && selection.getSelectedCount() === 0) {
      const obj = data.data[0];

      const rows = data?.data.map((item: any) => {
        return {
          key: item.reviewId,
          feedback: item.feedback,
          rating: item.rating,
          issue_area: item.IssueArea,
          sme_email: item.smeEmail,
          comment: item.comment,
        };
      });
      selection.setItems(rows);
      selection.setKeySelected(obj.reviewId, true, false);
      selection.setChangeEvents(true);
    }
  };

  const email = context?.user.email;
  const { isLoading, isError, data } = useQuery(
    "getSMEFeedback",
    () => {
      return axios.get(
        `https://59f4aemlt5.execute-api.us-east-2.amazonaws.com/Prod/sme/${email}`
      );
    },
    { onSuccess: handleQuerySuccess }
  );

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Comment is submitted");
  };

  const mutation = useMutation(
    (data: SmeFeedbackType) => {
      return axios.post(
        "https://59f4aemlt5.execute-api.us-east-2.amazonaws.com/Prod/sme/comments",
        data
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const { values, handleSubmit, errors, touched, handleChange } = useFormik({
    initialValues: getInitialData(
      // @ts-ignore
      selectedItem?.key,
      // @ts-ignore
      selectedItem?.sme_email,
      // @ts-ignore
      selectedItem?.rating,
      // @ts-ignore
      selectedItem?.feedback,
      // @ts-ignore
      selectedItem?.issue_area
    ),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const getCommentBox = () => {
    return (
      <div>
        <StyledContent>
          <Label styles={labelStyles}>Comment</Label>
          <TextField
            value={values.comment}
            styles={{ root: { width: "270px", marginLeft: "10px" } }}
            onChange={handleChange}
            name="comment"
            multiline
            errorMessage={touched?.comment ? errors?.comment : undefined}
          />
        </StyledContent>
        <ButtonContainer>
          <PrimaryButton
            // @ts-ignore
            onClick={handleSubmit}
            text={"Post"}
            disabled={mutation.isLoading}
          />
        </ButtonContainer>
      </div>
    );
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      return <div>Something went wrong</div>;
    }

    if (!isLoading && !isError && !data?.data.length) {
      return <div>No feedback found</div>;
    }

    const rows = data?.data.map((item: any) => {
      return {
        key: item.reviewId,
        feedback: item.feedback,
        rating: item.rating,
        issue_area: item.IssueArea,
        sme_email: item.smeEmail,
        comment: item.comment,
      };
    });

    return (
      <div>
        <DetailsList
          items={rows}
          columns={columns}
          selectionMode={SelectionMode.single}
          checkboxVisibility={CheckboxVisibility.always}
          selection={selection}
          setKey="exampleList"
        />
        {!!selectedItem && getCommentBox()}
      </div>
    );
  };

  return <div>{getContent()}</div>;
};

export default SmeFeedback;
