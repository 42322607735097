import * as Yup from "yup";

type RoleType = "sme" | "customer" | "admin";

export interface UserRegistrationType {
  email: string;
  password: string;
  group: RoleType;
}

export const initialData: UserRegistrationType = {
  email: "",
  password: "",
  group: "customer",
};

export const schema = Yup.object().shape({
  email: Yup.string().label("Email").trim().required().email(),
  password: Yup.string()
    .label("Password")
    .trim()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  group: Yup.string().label("Role").oneOf(["sme", "customer" , "admin"]).required(),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().label("Email").trim().required().email(),
});
