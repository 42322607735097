import * as Yup from "yup";
import dateFormat, { masks } from "dateformat";
export enum PaymentMethodId {
  CreditCard = "Credit Card",
  DebitCard = "Debit Card",
}

export enum CardType {
  Visa = "Visa",
  Master = "Master",
  Discover = "Discover",
}

export interface PaymentSettingType {
  makeAsDefault: boolean;
  cardNumber: string;
  paymentMethod: PaymentMethodId;
  cardExpiry: string;
  nameOnCard: string;
  securityCode: string;
  customerEmail: string;
  cardType: CardType;
  paymentMethodId: number;
  zipCode: string;
  defaultselection?: boolean;
}

export const getInitialData = (email?: string): PaymentSettingType => ({
  makeAsDefault: false,
  cardNumber: "",
  paymentMethod: PaymentMethodId.CreditCard,
  cardExpiry: "",
  nameOnCard: "",
  securityCode: "",
  customerEmail: email ?? "",
  cardType: CardType.Visa,
  paymentMethodId: 0,
  zipCode: "",
});

const currentYear = new Date().getFullYear() % 100;
const currentMonth = new Date().getMonth() + 1;

export const schema =
  /*  console.log("date format",dateFormat(now, "mm-yy")); */
  Yup.object().shape({
    nameOnCard: Yup.string()
      .matches(/^[a-z]+$/i, "Invalid Name")
      .label("Name on Card")
      .trim()
      .required()
      .max(50),
    securityCode: Yup.string()
      .label("Security Code")
      .trim()
      .required()
      .max(4)
      .min(3),
    cardExpiry: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, "Invalid date format")
      .test(
        "is-future-date",
        "Card expiry date must be future date",
        function (value) {
          if (!value) return true;

          const [month, year] = value.split("/");

          const inputYear = parseInt(year, 10);
          const inputMonth = parseInt(month, 10);

          if (inputYear >= currentYear) {
            if (inputYear === currentYear) {
              if (inputMonth >= currentMonth) {
                return true;
              } else {
                return false;
              }
            }

            return true;
          } else {
            return false;
          }
        }
      )
      .trim()
      .label("Card Expiry")
      .required(),
    makeAsDefault: Yup.bool().required(),
    cardNumber: Yup.string()
      .label("Card Number")
      .trim()
      .required()
      // .max(16)
      // .min(13)
      .matches(/^[0-9]{13,16}$/, "Card Number must be of a valid number"),
    paymentMethod: Yup.string().required(),
    cardType: Yup.string().required(),
    zipCode: Yup.string().required(),
  });
