import axios, { AxiosError, AxiosResponse } from "axios";
import * as React from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import "./Sign.css";
import {
  PrimaryButton,
  TextField,
  Label,
  ChoiceGroup,
  Link,
  MessageBar,
  MessageBarType,
  Spinner,
  IChoiceGroupOption,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  LinkContainer,
  StyledContent,
  textFieldStyles,
} from "./SignInForm.styles";
import {
  forgetPasswordSchema,
  initialData,
  schema,
  UserRegistrationType,
} from "./SignInForm.utils";
import { useNavigate } from "react-router-dom";
import { getServerError } from "../../utils/utils";
import CurrentUserContext from "../../CurrentUserContext";

const SignInForm = () => {
  const [selectedKey, setSelectedKey] = React.useState<string>("customer");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const context = React.useContext(CurrentUserContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  }, [errorMessage]);

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    context?.setUser({
      ...context?.user,
      role: values.group,
      email: values.email,
    });
    values.group === "admin" ? navigate(`/reports`) : navigate(`/profile`);
  };

  const mutation = useMutation(
    (data: UserRegistrationType) => {
      return axios.post(
        "https://gxiv4d2ndh.execute-api.us-east-1.amazonaws.com/dev/login",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const handleCheckUserSuccess = (succ: AxiosResponse) => {
    succ.data?.length || succ.data?.email
      ? navigate(
          `/forget-password/?email=${values.email}&group=${values.group}`
        )
      : setErrorMessage("User is not registered");
  };

  const checkUserExists = useMutation(
    () => {
      return axios.get(
        values.group === "customer"
          ? `https://5649vz4969.execute-api.us-east-2.amazonaws.com/Prod/${values.email}`
          : `https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/sme/${values.email}`,
        {
          headers: {},
        }
      );
    },
    { onSuccess: handleCheckUserSuccess }
  );
  const handleForgotPassword = () => {
    forgetPasswordSchema.isValidSync(values)
      ? checkUserExists.mutate()
      : setErrorMessage(
          "A valid email is required to access forgot password page"
        );
  };
  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialData,
    validationSchema: schema,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  function _onChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement | undefined>,
    option?: IChoiceGroupOption | undefined
  ): void {
    if (option) {
      setFieldValue("group", option.key);
      setSelectedKey(option.key);
    }
  }
  const options = [
    { key: "customer", text: "Login as Customer" },
    { key: "sme", text: "Login as SME" },
    { key: "admin", text: "Login as Admin" },
  ];
  return (
    <div>
      {errorMessage && (
        <MessageBar messageBarType={MessageBarType.error}>
          {errorMessage}
        </MessageBar>
      )}
      {checkUserExists.isLoading && <Spinner />}
      <StyledContent>
        <Label styles={labelStyles}>User Name (Email)</Label>
        <TextField
          value={values.email}
          onChange={handleChange}
          name="email"
          styles={textFieldStyles}
          errorMessage={touched?.email ? errors?.email : undefined}
        />
      </StyledContent>
      <StyledContent>
        <Label styles={labelStyles}>Password</Label>
        <TextField
          value={values.password}
          onChange={handleChange}
          name="password"
          type="password"
          styles={textFieldStyles}
          errorMessage={touched?.password ? errors?.password : undefined}
        />
      </StyledContent>
      <StyledContent>
        <ChoiceGroup
          selectedKey={selectedKey}
          options={options}
          onChange={_onChange}
          name="role"
          required={true}
        />
      </StyledContent>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={handleSubmit}
          text={"Sign In"}
          disabled={mutation.isLoading}
        />
        <PrimaryButton
          text={"Reset"}
          // @ts-ignore
          onClick={resetForm}
          styles={{ root: { float: "right", marginRight: "20px" } }}
        />
      </ButtonContainer>
      <LinkContainer>
        <Link onClick={handleForgotPassword}>Forgot Password?</Link>
        <Link styles={{ root: { float: "right", marginRight: "20px" } }}>
          Reset Password
        </Link>
      </LinkContainer>
    </div>
  );
};

export default SignInForm;
