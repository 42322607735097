import { ILabelStyles, ITextFieldStyles } from "@fluentui/react";
import styled from "styled-components";

export const StyledContent = styled.div`
  display: flex;
  align-items: baseline;
  padding: 15px 20px;
`;

export const labelStyles: ILabelStyles = {
  root: {
    width: "150px",
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: "350px",
  },
};

export const ButtonContainer = styled.div`
  text-align: left;
  margin-left: 165px;
`;

export const LinkContainer = styled(ButtonContainer)`
  text-align: left;
  margin-left: 165px;
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
`;
