import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import { PrimaryButton, TextField, Label } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./ForgotPasswordForm.styles";
import {
  schema,
  ForgetPasswordType,
  RoleType,
} from "./ForgotPasswordForm.utils";
import { useSearchParams } from "react-router-dom";
import { getServerError } from "../../utils/utils";

const SignInForm = () => {
  const [params] = useSearchParams();
  const email = params.get("email");
  const navigate = useNavigate();
  const group: RoleType = (params.get("group") as RoleType) ?? "customer";
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Password is changed.");
    navigate(`/sign-in`)
  };

  const mutation = useMutation(
    (data: ForgetPasswordType) => {
      return axios.post(
        "https://gxiv4d2ndh.execute-api.us-east-1.amazonaws.com/dev/signup",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const { values, touched, errors, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: { password: "", confirmPassword: "" },
      validationSchema: schema,
      onSubmit: (values) => {
        mutation.mutate({
          email: email ?? "",
          password: values.password,
          group: group,
        });
      },
    });

  return (
    <div>
      <>
        <StyledContent>
          <Label styles={labelStyles}>New Password</Label>
          <TextField
            value={values.password}
            onChange={handleChange}
            name="password"
            type="password"
            styles={textFieldStyles}
            errorMessage={touched?.password ? errors?.password : undefined}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Confirm Password</Label>
          <TextField
            value={values.confirmPassword}
            onChange={handleChange}
            name="confirmPassword"
            type="password"
            styles={textFieldStyles}
            errorMessage={
              touched?.confirmPassword ? errors?.confirmPassword : undefined
            }
          />
        </StyledContent>
      </>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={handleSubmit}
          text={"Submit"}
          disabled={mutation.isLoading}
        />
        <PrimaryButton
          text={"Reset"}
          // @ts-ignore
          onClick={resetForm}
          styles={{ root: { float: "right", marginRight: "20px" } }}
        />
      </ButtonContainer>
    </div>
  );
};

export default SignInForm;
