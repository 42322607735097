import React, { createContext, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter } from "react-router-dom";
import Routes from "./components/Routes/Routes";
import Footer from "./components/Footer/Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import styled from "styled-components";
import { USER_SESSION_KEY } from "./utils/utils";
import UserAuthNav from "./components/Navbar/UserAuthNav";
import UserAuthRoute from "./components/Routes/UserAuthRoutes";
import SmeAuthNav from "./components/Navbar/SmeAuthNav";
import SmeAuthRoute from "./components/Routes/SmeAuthRoutes";
import CurrentUserContext from "./CurrentUserContext";
import AdminAuthRoutes from "./components/Routes/AdminAuthRoutes";
import AdminAuthNav from "./components/Navbar/AdminAuthNav";
import KommunicateChat from './chat';
const queryClient = new QueryClient();

const StyledContainer = styled.div`
  padding-bottom: 2.5rem;
`;

function App() {
  const [user, setUser] = React.useState(
    JSON.parse(sessionStorage.getItem(USER_SESSION_KEY) ?? "{}")
  );

  useEffect(() => {
    sessionStorage.setItem(USER_SESSION_KEY, JSON.stringify(user));
  }, [user]);

  const getContent = () => {
    if (user?.role === "customer") {
      return (
        <>
          <UserAuthNav />
          <UserAuthRoute />
	  <KommunicateChat/>
        </>
      );
    }

    if (user?.role === "sme") {
      return (
        <>
          <SmeAuthNav />
          <SmeAuthRoute />
	  <KommunicateChat/>
        </>
      );
    }
    if (user?.role === "admin") {
      return (
        <>
          <AdminAuthNav />
          <AdminAuthRoutes />
        </>
      );
    }

    return (
      <>
        <Navbar />
        <Routes />
      </>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <CurrentUserContext.Provider value={{ user, setUser }}>
          <StyledContainer>{getContent()}</StyledContainer>
        </CurrentUserContext.Provider>
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
