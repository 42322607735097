import {
  ChoiceGroup,
  IChoiceGroupOption,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import CurrentUserContext from "../../CurrentUserContext";
import { getServerError } from "../../utils/utils";
import PaymentSettingForm from "./SmeBankAccountForm/SmeBankAccountForm";
import { PaymentSettingType } from "./SmeBankAccountForm/SmeBankAccountForm.utils";

const UserProfile = () => {
  const context = React.useContext(CurrentUserContext);
  const email = context?.user.email;
  const [selectedPayment, setSelectedPayment] = React.useState<
    number | undefined
  >(undefined);
  const [initialData, setInitialData] = React.useState<
    PaymentSettingType | undefined
  >(undefined);
  const [disabled, setDisabled] = React.useState<boolean | undefined>(
    undefined
  );

  const { data, isLoading, isError } = useQuery("getBankAccounts", () => {
    return axios.get(
      `https://5luv8fybqj.execute-api.us-east-2.amazonaws.com/Prod/sme/paymentmethods/${email}`,
      {
        headers: {},
      }
    );
  });

  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Bank Account is successfully deleted");
  };

  const mutation = useMutation(
    () => {
      return axios.delete(
        `https://5luv8fybqj.execute-api.us-east-2.amazonaws.com/Prod/${selectedPayment}`
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const handleDelete = () => {
    const selectedData = data?.data?.find(
      (item: { paymentMethodId: number }) =>
        item.paymentMethodId === selectedPayment
    );
    if (selectedData.makeAsDefaultPayment) {
      alert("You can not delete default bank account");
    } else {
      mutation.mutate();
    }
  };

  const handleChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setSelectedPayment(option?.key ? Number(option?.key) : undefined);
    const selectedData = data?.data?.find(
      (item: { paymentMethodId: number }) =>
        item.paymentMethodId === Number(option?.key)
    );
    setInitialData({
      makeAsDefaultPayment: selectedData.makeAsDefaultPayment ?? false,
      bankRoutingNumber: selectedData.bankRoutingNumber,
      paymentMethodId: selectedData.paymentMethodId,
      accountNumber: selectedData.accountNumber,
      accountName: selectedData.accountName,
      smeEmail: selectedData.smeEmail,
      bankName: selectedData.bankName,
    });
    setDisabled(true);
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      <div>Something went wrong</div>;
    }
    const options: IChoiceGroupOption[] | undefined = Array.isArray(data?.data)
      ? data?.data?.map(
          (item: {
            cardNumber: string;
            bankName: string;
            accountNumber: string;
            paymentMethodId: number;
            accountName: string;
          }) => ({
            key: String(item?.paymentMethodId),
            text: `${item?.bankName?.substring(
              0,
              7
            )}...-${item?.accountName.substring(0, 7)}...`,
          })
        )
      : [];

    return options?.length ? (
      <div
        style={{
          display: "flex",
          height: "fit-content",
          backgroundColor: "white",
          padding: "10px",
          gap: "10px",
        }}
      >
        <ChoiceGroup
          options={options}
          onChange={handleChange}
          selectedKey={selectedPayment}
        />
        <div>
          <PrimaryButton
            text="Delete"
            onClick={handleDelete}
            disabled={!selectedPayment || mutation.isLoading}
            styles={{ root: { marginBottom: "5px" } }}
          />
          {mutation.isLoading && <Spinner />}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const handleReset = () => {
    setDisabled(undefined);
    setInitialData(undefined);
    setSelectedPayment(undefined);
  };

  return (
    <div style={{ display: "flex" }}>
      <PaymentSettingForm
        initialData={initialData}
        disabled={disabled}
        onReset={handleReset}
      />
      {getContent()}
    </div>
  );
};

export default UserProfile;
