import { Spinner } from "@fluentui/react";
import * as React from "react";
import "./loaders.css";

const SpinningLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="spinner_wrapper">
      <Spinner />
    </div>
  );
};

export default SpinningLoader;
