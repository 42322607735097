import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import { PrimaryButton, TextField, Label, Checkbox } from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
  textFieldStyles,
} from "./SmeBankAccountForm.styles";
import {
  schema,
  PaymentSettingType,
  getInitialData,
} from "./SmeBankAccountForm.utils";
import { getServerError } from "../../../utils/utils";
import CurrentUserContext from "../../../CurrentUserContext";

interface PaymentSettingsForm {
  initialData?: PaymentSettingType;
  disabled?: boolean;
  onReset?: () => void;
}

const PaymentSettingForm = ({
  initialData,
  disabled,
  onReset,
}: PaymentSettingsForm) => {
  const context = React.useContext(CurrentUserContext);
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };

  const handleSuccess = () => {
    alert("Account Number is successfully added");
  };

  const mutation = useMutation(
    (data: PaymentSettingType) => {
      return axios.post(
        "https://5luv8fybqj.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialData ?? getInitialData(context?.user.email),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.accountNumber = String(values.accountNumber);
      values.bankRoutingNumber = String(values.bankRoutingNumber);
      values.paymentMethodId = values.paymentMethodId
        ? values.paymentMethodId
        : Date.now();
      mutation.mutate(values);
    },
  });

  const handleReset = () => {
    onReset?.();
    resetForm();
  };

  return (
    <div>
      <>
        <StyledContent>
          <Label styles={labelStyles}>Account Name</Label>
          <TextField
            value={String(values.accountName)}
            onChange={handleChange}
            name="accountName"
            styles={textFieldStyles}
            errorMessage={
              touched?.accountName ? errors?.accountName : undefined
            }
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Bank Name</Label>
          <TextField
            value={values.bankName}
            onChange={handleChange}
            name="bankName"
            styles={textFieldStyles}
            errorMessage={touched?.bankName ? errors?.bankName : undefined}
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Account Number</Label>
          <TextField
            value={String(values.accountNumber)}
            onChange={handleChange}
            name="accountNumber"
            styles={textFieldStyles}
            type="number"
            min="0"
            minLength={9}
            maxLength={18}
            errorMessage={
              touched?.accountNumber ? errors?.accountNumber : undefined
            }
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Routing Number</Label>
          <TextField
            value={String(values.bankRoutingNumber)}
            onChange={handleChange}
            name="bankRoutingNumber"
            styles={textFieldStyles}
            type="number"
            min="0"
            errorMessage={
              touched?.bankRoutingNumber ? errors?.bankRoutingNumber : undefined
            }
            disabled={disabled}
          />
        </StyledContent>
        <StyledContent>
          <Label styles={labelStyles}>Make this payment Method Default</Label>
          <Checkbox
            checked={values.makeAsDefaultPayment}
            onChange={handleChange}
            name="makeAsDefaultPayment"
          />
        </StyledContent>
      </>

      <ButtonContainer>
        <PrimaryButton
          // @ts-ignore
          onClick={handleSubmit}
          text={"Add Bank Detail"}
          disabled={mutation.isLoading}
        />
        <PrimaryButton
          text={"Clear"}
          onClick={handleReset}
          styles={{ root: { float: "right", marginRight: "20px" } }}
        />
      </ButtonContainer>
    </div>
  );
};

export default PaymentSettingForm;
