import * as React from "react";
import { Route, Routes } from "react-router-dom";
import SmeAuthLayout from "../Layout/SmeAuthLayout";
import SignOut from "../SignOut/SignOut";
import SmeAppointmentLayout from "../Layout/SmeAppointmentLayout";
import SmeAppointment from "../SmeAppointment/SmeAppointment";
import SmeFeedbackLayout from "../Layout/SmeFeedbackLayout";
import SmeFeedback from "../SmeFeedback/SmeFeedback";
import SmeProfilePage from "../SmeProfilePage/SmeProfilePage";
import AppoinitmentDetailsLayout from '../AppointmentDetailsForm/AppointmentDetailsStyles';
import AppoinitmentDetailsForm from '../AppointmentDetailsForm/AppoinitmentDetailsForm';

const SmeAuthRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SmeAuthLayout>
            <SmeProfilePage />
          </SmeAuthLayout>
        }
      />
      <Route
        path="/profile"
        element={
          <SmeAuthLayout>
            <SmeProfilePage />
          </SmeAuthLayout>
        }
      />

      <Route
        path="appointments"
        element={
          <SmeAppointmentLayout>
            <SmeAppointment />
          </SmeAppointmentLayout>
        }
      />
      <Route
        path="feedback"
        element={
          <SmeFeedbackLayout>
            <SmeFeedback />
          </SmeFeedbackLayout>
        }
      />
      <Route
        path="sign-out"
        element={
          <SmeAuthLayout>
            <SignOut />
          </SmeAuthLayout>
        }
      /> < Route
      path="/appointmentdetailspage/:key"
      element={
        < AppoinitmentDetailsLayout >
          <AppoinitmentDetailsForm />
        </AppoinitmentDetailsLayout >
      }
    />
      <Route
        path="help"
        element={<SmeAuthLayout>This is help page</SmeAuthLayout>}
      />
      <Route element={<SmeAuthLayout>Page Not found 404</SmeAuthLayout>} path="*" />
    </Routes>
  );
};

export default SmeAuthRoute;
