import axios, { AxiosError } from "axios";
import * as React from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import {
  PrimaryButton,
  Label,
  Dropdown,
  IDropdownOption,
  Checkbox,
  ChoiceGroup,
  TextField,
  IChoiceGroupOption,
  mergeStyles,
  DefaultButton,
} from "@fluentui/react";
import {
  ButtonContainer,
  labelStyles,
  StyledContent,
} from "./CustomerAppointmentForm.styles";
import {
  CustomerAppointmentType,
  getInitialData,
  schema,
  getTimeInterval,
  getExcludeTime,
} from "./CustomerAppointmentForm.utils";
import { getServerError, subjectAreas } from "../../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import format from "date-fns/format";
import { useQuery } from "react-query";
import styled from "styled-components";

const AppointButtonContainer = styled(ButtonContainer)`
  display: flex;
  gap: 10px;
`;

let smeEmailGlobal = "";
interface SmeRegistrationFormProps {
  userData: {
    lastName: string;
    email: string;
    firstName: string;
  };
  rescheduleObj: any;
  resetAll: () => void;
}

const SmeRegistrationForm = ({
  userData,
  rescheduleObj,
  resetAll,
}: SmeRegistrationFormProps) => {
  const handleError = (error: AxiosError) => {
    alert(getServerError(error));
  };
  const [smeObj, setSmeObj] = React.useState<
    { consultingFee: string; backgroundProficiency: string; firstName: string; lastName: string } | undefined
  >();
  const [smeDetails, setSmeDetails] = React.useState([]);
  const getLocalStorageExcludedTimes = () => {
    const excludedTimes = localStorage.getItem("excludedTimes");
    return excludedTimes ? JSON.parse(excludedTimes) : [];
  };

  const [excludeDates, setExcludeDates] = React.useState<string[]>(
    getLocalStorageExcludedTimes()
  );

  const handleSMEdetails = (response: any) => {
    setSmeDetails(response?.data);
    if (rescheduleObj && Object.keys(rescheduleObj).length) {
      setFieldValue("smeEmail", rescheduleObj.smeEmail);
      setFieldValue("appointmentTime", rescheduleObj.appointmentTime);
      setFieldValue("appointmentDuration", rescheduleObj.appointmentDuration);
      setFieldValue("agreeToTerms", true);
    }
    setErrors({});
  };

  React.useEffect(() => {
    setErrors({});
  }, []);

  React.useEffect(() => {
    if (rescheduleObj && Object.keys(rescheduleObj).length) {
      setFieldValue("subjectArea", rescheduleObj.subjectArea);
      setTimeout(() => {
        getSmeList.mutate();
      }, 100);
    }
    setErrors({});
  }, [rescheduleObj]);

  const getSmeDetail = useMutation(
    (email: string) => {
      return axios.get(
        `https://1b2jwnwxo5.execute-api.us-east-2.amazonaws.com/Prod/by/${email}`,
        {
          headers: {},
        }
      );
    },
    { onSuccess: handleSMEdetails }
  );

  const handleGetSmeListSuccess = (data: {
    data: {
      email: string;
      firstName: string;
      lastName: string;
      backgroundProficiency: string,
      consultationFee: string;
    }[];
  }) => {
    setErrors({});
    let obj = data?.data?.[0];
    if (rescheduleObj && Object.keys(rescheduleObj).length) {
      let newObj: any =
        data?.data.find((val) => val.email === rescheduleObj.smeEmail) || {};
      setFieldValue("smeEmail", rescheduleObj?.smeEmail);
      setFieldValue("smeFirstName", rescheduleObj?.smeFirstName);
      setFieldValue("smeLastName", rescheduleObj?.smeLastName);
      smeEmailGlobal = rescheduleObj?.smeEmail;
      setSmeObj({
        consultingFee: newObj?.consultationFee,
	backgroundProficiency: newObj?.backgroundProficiency,
        firstName: newObj?.firstName,
        lastName: newObj?.lastName,
      });
    } else {
      setSmeObj({
        consultingFee: obj?.consultationFee,
	backgroundProficiency: obj?.backgroundProficiency,
        firstName: obj?.firstName,
        lastName: obj?.lastName,
      });
      smeEmailGlobal = obj?.email;
      setFieldValue("smeEmail", obj?.email);
      setFieldValue("smeFirstName", obj?.firstName);
      setFieldValue("smeLastName", obj?.lastName);
    }
    getSmeDetail.mutate(obj?.email);
    refetch();
  };

  const getSmeList = useMutation(
    () => {
      return axios.get(
        `https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/sme/subjects/${values.subjectArea}`,
        {
          headers: {},
        }
      );
    },
    { onSuccess: handleGetSmeListSuccess }
  );

  const handleSuccess = () => {
    setFieldValue("subjectArea", "");
    setFieldValue("smeEmail", "");
    setFieldValue("appointmentTime", "");
    setFieldValue("appointmentDuration", "");
    setFieldValue("agreeToTerms", false);
    getSmeList.reset();
    resetAll();
    setErrors({});
    alert("Appointment is Booked");
    window.location.reload();
  };

  const mutation = useMutation(
    (data: CustomerAppointmentType) => {
      return axios.post(
        "https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/",
        data,
        {
          headers: {},
        }
      );
    },
    { onError: handleError, onSuccess: handleSuccess }
  );

  const {
    setFieldValue,
    values,
    handleSubmit,
    errors,
    touched,
    handleChange,
    setErrors,
  } = useFormik({
    initialValues: getInitialData(
      userData?.email,
      userData?.firstName,
      userData?.lastName
    ),
    validationSchema: schema,
    onSubmit: (values) => {
      if (rescheduleObj && Object.keys(rescheduleObj).length) {
        values.appointmentId = rescheduleObj.appointmentId;
      } else {
        values.appointmentId = Date.now();
      }
      mutation.mutate(values);
    },
  });

  const { data: booked_data, refetch } = useQuery(
    ["fetch-booked-status"],
    () => {
      return axios.get(
        `https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/sme/appointments/${smeEmailGlobal}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const onToggleChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("subjectArea", item.key);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(date, "MM-dd-yyyy hh:mm aa");
      setFieldValue("appointmentTime", formattedDate);
    }
  };

  const handleSmeSearch = () => {
    if (!values.subjectArea) {
      alert("Please select subject area");
      return;
    }
    getSmeList.mutate();
  };

  const handleSmeSelect = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    smeEmailGlobal = option?.key || "";
    setFieldValue("smeEmail", option?.key);
    setFieldValue(
      "smeFirstName",
      option?.imageAlt ? JSON.parse(option?.imageAlt)?.firstName : undefined
    );
    setFieldValue(
      "smeLastName",
      option?.imageAlt ? JSON.parse(option?.imageAlt)?.lastName : undefined
    );
    setSmeObj(option?.imageAlt ? JSON.parse(option?.imageAlt) : undefined);
    setFieldValue("appointmentTime", undefined);
    getSmeDetail.mutate(option?.key ?? "");
    refetch();
  };

  const { data, isLoading } = useQuery("getAppointments", () => {
    return axios.get(
      `https://adwue0temh.execute-api.us-east-2.amazonaws.com/Prod/appointments/${userData?.email}`
    );
  });

  const handleSelectDate = (date: any) => {
    let newDate = new Date(date).setHours(0, 0, 0, 0);

    const excludeDate = getExcludeTime(
      smeDetails || [],
      [...booked_data?.data, ...data?.data] || []
    );
    const filterDates = excludeDate
      .filter((item) => {
        return (
          new Date(item.split(" ")[0]).getTime() === new Date(newDate).getTime()
        );
      })
      .map((item) => {
        const splitDate = item.split(" ");
        return `${splitDate[1]} ${splitDate[2]}`;
      });

    setTimeout(() => {
      const node = document
        .getElementsByClassName("react-datepicker__time-list")[0]
        .querySelectorAll("li");

      node.forEach((item: any) => {
        item.classList.remove("excludeDate");
      });

      if (filterDates?.length > 0) {
        node.forEach((item: any) => {
          let itemContent = item?.textContent;
          if (isNaN(itemContent.substr(0, 2))) {
            itemContent =
              itemContent.slice(0, 0) +
              "0" +
              itemContent.slice(0, itemContent.length);
          }
          if (filterDates.includes(itemContent)) {
            item.classList.add("excludeDate");
          }
        });
      }
    }, 0);
  };

  const onAppointmentDurationChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    if (item) {
      setFieldValue("appointmentDuration", item.key);
    }
  };

  // // Function to store excluded time slots in localStorage
  // const storeExcludedTimesInLocalStorage = (excludeTimes: any) => {
  //   localStorage.setItem('excludeTimes', JSON.stringify(excludeTimes));
  // };

  // // Function to retrieve excluded time slots from localStorage
  // const getExcludedTimesFromLocalStorage = () => {
  //   const storedExcludeTimes = localStorage.getItem('excludeTimes');
  //   return storedExcludeTimes ? JSON.parse(storedExcludeTimes) : [];
  // };

  // // Calculate the excluded time slots
  // const excludeTimes = React.useMemo(() => {
  //   // Calculate your excluded time slots as before
  //   const excludedTimes = getExcludeTime(smeDetails || [], booked_data?.data || []);

  //   // Store the excluded times in localStorage
  //   storeExcludedTimesInLocalStorage(excludedTimes);

  //   return excludedTimes;
  // }, [smeDetails, booked_data]);

  // // Check for excluded time slots in localStorage when the page loads
  // React.useEffect(() => {
  //   const storedExcludeTimes = getExcludedTimesFromLocalStorage();
  //   if (storedExcludeTimes.length > 0) {
  //     // Exclude the stored time slots in the date picker
  //     const excludedTimes = storedExcludeTimes.map((time: string | number | Date) => new Date(time));
  //     // Use setExcludedTimes or your preferred method to exclude these times in the date picker
  //     // setExcludedTimes(excludedTimes);
  //   }
  // }, []);

  return (
    <div>
      <StyledContent>
        <Label styles={labelStyles}>Select Subject Area</Label>
        <div>
          <Dropdown
            options={subjectAreas}
            selectedKey={values.subjectArea}
            onChange={onToggleChange}
            placeholder="Select options"
            styles={{
              dropdown: { width: 350, marginBottom: 10 },
            }}
          />
          <PrimaryButton
            onClick={handleSmeSearch}
            text={"Search"}
            disabled={getSmeList.isLoading}
          />
        </div>
      </StyledContent>
      {getSmeList.isLoading ? (
        <div>{"Loading..."}</div>
      ) : !getSmeList.data?.data.length ? (
        <div>No SME Found</div>
      ) : (
        <div>
          <div>
            <StyledContent>
              <Label styles={labelStyles}>Available SMEs</Label>
              <div
                style={{
                  display: "flex",
                  height: 100,
                  backgroundColor: "white",
                  padding: "10px",
                  gap: "10px",
                  overflow: "scroll",
                  width: 200,
                }}
              >
                <ChoiceGroup
                  options={getSmeList?.data?.data.map(
                    (item: {
                      firstName: string;
                      lastName: string;
                      email: string;
                      consultationFee: string;
		      backgroundProficiency: string;
                    }) => ({
                      key: String(item.email),
                      text: `${item.firstName} ${item.lastName}`,
                      imageAlt: JSON.stringify({
                        consultingFee: item.consultationFee,
			backgroundProficiency: item.backgroundProficiency,
                        firstName: item.firstName,
                        lastName: item.lastName,
                      }),
                    })
                  )}
                  onChange={handleSmeSelect}
                  selectedKey={values.smeEmail}
                />
              </div>
            </StyledContent>
            <StyledContent>
              <Label styles={labelStyles}>Consulting Fee per hour:</Label>
              <Label styles={labelStyles}>{smeObj?.consultingFee}</Label>
            </StyledContent>
	    <StyledContent>
	      <Label styles={labelStyles}>Background and Proficiency:</Label>
	      <Label styles={labelStyles}>{smeObj?.backgroundProficiency}</Label>
	      </StyledContent>

            <StyledContent>
              <Label styles={labelStyles}>Appointment Date/Time</Label>
              <div>
                {getSmeDetail?.isLoading ? (
                  "Fetching Date..."
                ) : (
                  <DatePicker
                    selected={
                      values.appointmentTime
                        ? new Date(values.appointmentTime)
                        : undefined
                    }
                    onChange={handleDateChange}
                    showTimeSelect
                    minDate={new Date()}
                    minTime={setMinutes(new Date(), 30)}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    timeIntervals={getTimeInterval(values.appointmentDuration)}
                    dateFormat="MM-dd-yyyy HH:mm aa"
                    wrapperClassName={mergeStyles({
                      input: { height: "32px" },
                    })}
                    onInputClick={() => setFieldValue("appointmentTime", "")}
                    onSelect={handleSelectDate}
                    onFocus={() =>
                      handleSelectDate(
                        values.appointmentTime
                          ? new Date(values.appointmentTime)
                          : new Date()
                      )
                    }
                  />
                )}

                {errors.appointmentTime && touched.appointmentTime ? (
                  <div style={{ fontSize: "10px", color: "red" }}>
                    {errors.appointmentTime}
                  </div>
                ) : null}
              </div>
            </StyledContent>
            <StyledContent>
              <Label styles={labelStyles}>Appointment Duration</Label>
              <Dropdown
                options={[
                  { key: "30 min", text: "30 min" },
                  { key: "1 hr", text: "1 hr" },
                  { key: "2 hr", text: "2 hr" },
                ]}
                selectedKey={values.appointmentDuration}
                onChange={onAppointmentDurationChange}
                placeholder="Select options"
                styles={{
                  dropdown: { width: 350 },
                }}
              />
            </StyledContent>
            <StyledContent style={{ alignItems: "baseline" }}>
              <Label styles={labelStyles}>Agree to terms</Label>
              <Checkbox
                checked={values.agreeToTerms}
                onChange={handleChange}
                name="agreeToTerms"
              />
              <TextField
                readOnly
                value={
                  "If the appointment has got no show, you will be charged for the consulring fee that has to be promissed. Any rescheduling or cancellation must be done 24hrs prior to your appointment.\nPlease agree to these terms"
                }
                multiline={true}
                styles={{ root: { width: "270px", marginLeft: "10px" } }}
                errorMessage={
                  touched?.agreeToTerms ? errors?.agreeToTerms : undefined
                }
              />
            </StyledContent>

            <AppointButtonContainer>
              <PrimaryButton
                // @ts-ignore
                onClick={handleSubmit}
                text={"Book Appointment"}
                disabled={mutation.isLoading}
              />
              <DefaultButton
                // @ts-ignore
                onClick={() => {
                  window.location.reload();
                  window.scrollTo(0, 0);
                }}
                text="Cancel"
              />
            </AppointButtonContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmeRegistrationForm;
