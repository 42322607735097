import { Link } from "@fluentui/react";
import axios from "axios";
import * as React from "react";
import { useQuery } from "react-query";
import CurrentUserContext from "../../CurrentUserContext";

import SmeAppointmentForm from "./SmeAppointmentForm/SmeAppointmentForm";
import UserDetail from "./UserDetail/UserDetail";

const SmeAppointment = () => {
  const context = React.useContext(CurrentUserContext);
  const email = context?.user.email;
  const [showUserDetail, setShowUserDetail] = React.useState<boolean>(false);
  const { data, isLoading } = useQuery("getSMEDetail", () => {
    return axios.get(
      `https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/sme/${email}`
    );
  });
  const name = data?.data.length
    ? `${data.data[0].firstName} ${data.data[0].lastName}`
    : "";

  const handleLinkClick = () => {
    setShowUserDetail(true);
  };

  if (isLoading) {
    return <div>Loading.....</div>;
  }

  return (
    <>
      <Link
        onClick={handleLinkClick}
        style={{ position: "relative", left: "40%" }}
      >
        {name}
      </Link>
      {showUserDetail && data?.data.length && (
        <UserDetail values={data.data[0]} />
      )}
      {data?.data.length && <SmeAppointmentForm userData={data.data[0]} />}
    </>
  );
};

export default SmeAppointment;
