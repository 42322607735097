import * as Yup from "yup";

export type RoleType = "sme" | "customer";

export interface ForgetPasswordType {
  email: string;
  password: string;
  group: RoleType;
}

export const schema = Yup.object().shape({
  password: Yup.string()
    .label("Password")
    .trim()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .label("Confirm Password")
    .trim()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
