import React from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { DefaultButton } from "@fluentui/react";
import SpinningLoader from "../../Loaders/SpinningLoader";
import { DetailsList } from "@fluentui/react";

const getSmeData = async () => {
  const { data } = await axios.get(
    "https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/"
  );
  return data;
};
const getFeedbackData = async () => {
  const { data } = await axios.get(
    "https://59f4aemlt5.execute-api.us-east-2.amazonaws.com/Prod"
  );
  return data;
};

const postApiData = async (data) => {
  const { data: response } = await axios.post(
    `https://oh820ikqz8.execute-api.us-east-2.amazonaws.com/Prod/`,
    data,
    { headers: { "Content-Type": "application/json" } }
  );
  return response;
};

const AdminReports = () => {
  const [loading, setLoading] = React.useState(false);

  const { data, isLoading, refetch } = useQuery("getSmeData", getSmeData, {
    cacheTime: 0,
    staleTime: Infinity,
    onSuccess: () => {
      setLoading(false);
    },
  });

  const { data: responseData } = useQuery("getFeedbackData", getFeedbackData, {
    cacheTime: 0,
    staleTime: Infinity,
    onSuccess: () => {
      setLoading(false);
    },
  });

  let combinedData = [];
  let isDisabled = "";
  if (Array.isArray(data) && Array.isArray(responseData)) {
    data?.forEach((val) => {
      // if (
      //   !("workingStatus" in val) ||
      //   val?.workingStatus?.toLowerCase() !== "terminated"
      // ) {
      combinedData.push({
        ...val,
        ...responseData.find((_val) => _val.smeEmail === val.email),
      });
      // }
    });

    // combinedData = data?.filter(
    //   (val) =>
    //     (!("workingStatus" in val) ||
    //       val?.workingStatus?.toLowerCase() !== "terminated") && {
    //       ...val,
    //       ...responseData.find((_val) => _val.smeEmail === val.email),
    //     }
    // );
  }

  const handleApprove = (elm) => {
    let agreeToTerms = "";
    data.map((item) => {
      if (item.email == elm.email) {
        agreeToTerms = item.agreeToTerms
      }
    });
    const areaOfExpertise = elm.areaOfExpertise.split('-')
    const {
      lastName,
      contactNumber,
      consultationFee,
      email,
      firstName,
    } = elm;
    let dataBody = {
      areaOfExpertise,
      lastName,
      agreeToTerms,
      contactNumber,
      consultationFee,
      email,
      firstName,
      workingStatus: "Terminated",
    };
    setLoading(true);
    mutate(dataBody);
  };

  const { mutate } = useMutation(postApiData, {
    onSuccess: (response) => {
      setLoading(false);
      alert("Terminated Successfully");
      refetch();
    },
    onError: (error) => {
      setLoading(false);
      alert(error?.message);
    },
  });
  if (isLoading || loading) {
    return <SpinningLoader isLoading={isLoading || loading} />;
  }

  const rows = combinedData
    ?.filter((elm) => elm?.email)
    ?.map((elm) => {
      const {
        email,
        areaOfExpertise,
        contactNumber,
        lastName,
        consultationFee,
        firstName,
        rating,
        feedback,
        comment,
      } = elm;
      const formattedAreaOfExpertise = (areaOfExpertise) => {
        const areaOfExp = typeof areaOfExpertise === "string" ? areaOfExpertise : JSON.stringify(areaOfExpertise)
        const step1 = areaOfExp.replace(/\[|\]/g, '');
        const step2 = step1.replace(/,/g, '-');
        const step3 = step2.replace(/'|"/g, '');
        return step3;
      }
      return {
        email,
        areaOfExpertise: formattedAreaOfExpertise(areaOfExpertise),
        contactNumber,
        lastName,
        consultationFee,
        firstName,
        rating: rating || "-",
        feedback: feedback || "-",
        comment: comment || "-",
      };
    });

  const columns = [
    {
      key: "column1",
      name: "First Name",
      fieldName: "firstName",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column2",
      name: "Last Name",
      fieldName: "lastName",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column3",
      name: "Email",
      fieldName: "email",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column4",
      name: "Contact Number",
      fieldName: "contactNumber",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column5",
      name: "Consultation Fee",
      fieldName: "consultationFee",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column6",
      name: "Area of Experties",
      fieldName: "areaOfExpertise",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column7",
      name: "Rating",
      fieldName: "rating",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column8",
      name: "Comment",
      fieldName: "comment",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column9",
      name: "Feedback",
      fieldName: "feedback",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
    },
    {
      key: "column10",
      name: "Terminate",
      fieldName: "terminate",
      isResizable: true,
      minWidth: 100,
      maxWidth: 130,
      onRender: (item) => (
        data.map((d) => {
          if (d.email == item.email) {
            isDisabled = d.workingStatus;
          }
        }),
        <DefaultButton
          onClick={() => {
            handleApprove(item);
          }}
          //style={isDisabled.toLowerCase() == "terminated" ? {
          //  backgroundColor: "#d2d2d2",
          //  color: "white"
         // } : { backgroundColor: 'white' }}
          //disabled={isDisabled.toLowerCase() == "terminated" ? true : false
	//  }
        >
          Terminate
        </DefaultButton>
      ),
    },
  ];

  return (
    <div style={{ padding: "30px" }}>
      {<SpinningLoader /> && loading}
      {!combinedData?.length ? (
        <center className="no_reports">No reports found </center>
      ) : (
        <center style={{ fontSize: "2rem", color: "black" }}>
          SME Reports
        </center>
      )}

      <DetailsList items={rows} columns={columns} />
    </div>
  );
};

export default AdminReports;
