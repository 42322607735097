import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../Layout/AuthLayout";
import ForgotPasswordForm from "../ForgotPasswordForm/ForgotPasswordForm";
import Home from "../Home/Home";
import Contactus from "../Contactus/Contactus";
import Layout from "../Layout/Layout";
import SignInForm from "../SignInForm/SignInForm";
import SmeRegistrationForm from "../SmeRegistrationForm/SmeRegistrationForm";
import UserRegistrationForm from "../UserRegistrationForm/UserRegistrationForm";

const UserRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route
        path="user-registration"
        element={
          <Layout>
            <UserRegistrationForm />
          </Layout>
        }
      />
      <Route
        path="sme-registration"
        element={
          <Layout>
            <SmeRegistrationForm />
          </Layout>
        }
      />
      <Route
        path="sign-in"
        element={
          <Layout>
            <SignInForm />
          </Layout>
        }
      />
      <Route
        path="forget-password"
        element={
          <AuthLayout>
            <ForgotPasswordForm />
          </AuthLayout>
        }
      />
      <Route path="contactus" element={<Layout><Contactus /></Layout>} />
      <Route element={<Layout>Page Not found</Layout>} path="*" />
    </Routes>
  );
};

export default UserRoute;
