import * as React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 0px 50px 20px 50px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
`;

const Footer = () => {
  return <StyledContainer>© Copyright 2022</StyledContainer>;
};

export default Footer;
